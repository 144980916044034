const projectManagement = {
	projectList: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfservicecategory/list'
	},
	saveProject: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfservicecategory/save'
	},
	updateProject: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfservicecategory/update'
	},
	deleteProject: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfservicecategory/delete'
	}
};
export default projectManagement;
