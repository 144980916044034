import React, { Component, Fragment } from 'react';
import './index.less';
import { Props, IotFormDataItem, State } from './interface';
import { EnvironmentOutlined } from '@ant-design/icons';
import ValidateIdCard from '@/utils/validateIdCard';
import PublishScope from '@/components/PublishScope';
import {
	Form,
	Input,
	Select,
	Button,
	TreeSelect,
	DatePicker,
	Row,
	Col,
	Radio,
	Checkbox,
	TimePicker,
	Cascader,
	message
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import ImageUpload from '../IotImgUpload';
import IotEditor from '../IotEditor';
import AddressSelect from '../IotAddressSelect';
import LocationModal, { LocationDataInterface } from '../IotMap';
import * as redux from 'react-redux';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { connect } = redux;
export type InterfaceIotFormData = IotFormDataItem;
class IotForm extends Component<Props, State> {
	private iotFormRef: any = React.createRef<FormInstance>();
	private iotMapRef: any;
	private editorRef = React.createRef();
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		return null;
	}
	constructor(props: Props) {
		super(props);
		this.state = {
			canSubmit: true
		};
	}
	componentDidMount(): void {
		this.props.onRef(this);
	}
	onFormCancel = () => {
		this.iotFormRef.current?.resetFields();
	};
	onFormSubmit = (val: any) => {
		Object.keys(val).map((key: any) => {
			if (val[key] === undefined || val[key] === 'undefined') {
				val[key] = '';
			}
		});
		const { formList = [] } = this.props;
		formList.map((item: any) => {
			if (item.renderType === 'datePicker') {
				const format = item.format || 'YYYY-MM-DD HH:mm:ss';
				if (val[item.dataIndex]) {
					val[item.dataIndex] = moment(val[item.dataIndex]).format(format);
				} else {
					val[item.dataIndex] = '';
				}
			}
		});
		if (this.state.canSubmit) {
			this.setState(
				{
					canSubmit: false
				},
				() => {
					this.props.onSubmit(val);
					this.timerOut = setTimeout(() => {
						this.setState({
							canSubmit: true
						});
					}, this.props.submitGap || 500);
				}
			);
		} else {
			return;
		}
	};
	timerOut: any;
	setFieldsValue = (values: AnyObject) => {
		const { currentRecord = {}, formList = [] } = this.props;
		formList.map((item: any) => {
			if (item.renderType === 'datePicker' && Object.keys(currentRecord).length > 0) {
				values[item.dataIndex] = currentRecord[item.dataIndex] ? moment(currentRecord[item.dataIndex]) : null;
			}
		});
		this.iotFormRef.current?.setFieldsValue({
			...values
		});
	};
	componentWillUnmount() {
		this.setState = () => false;
	}

	renderFormItem = (item: any, index: number) => {
		const { currentRecord = {}, formDisabled = false } = this.props;
		const {
			extra = {
				maxLength: 30
			},
			required = false,
			MultiSelect = false,
			disabled = formDisabled,
			dataSource = [],
			dataIndex,
			title,
			titleStr,
			formatTime,
			renderType,
			photoType,
			photoView,
			aswSysCode,
			plateNoPicUrl,
			formItemProps,

			customSelectOption = {
				buttonOption: {
					text: '点击选择',
					label: ''
				},
				isInputShow: true,
				isStyle: false,
				onClick: () => {}
			},
			showTime
		} = item;
		const formItemExtra: any = {
			label: title,
			name: dataIndex,
			className: 'iot-form-item',
			...formItemProps
		};
		if (item.render) {
			return item.render();
		} else if (renderType === 'customSelect') {
			const btnOps = {
				span: customSelectOption.isInputShow ? 4 : 24,
				style: customSelectOption.isInputShow ? { marginTop: 30 } : {}
			};
			return (
				<Row gutter={8}>
					{customSelectOption.isInputShow ? (
						<Col span={19}>
							<Form.Item rules={[{ required, message: `请输入${titleStr || title}` }]} {...formItemExtra}>
								<Input disabled placeholder={`请选择${titleStr || title}`} />
							</Form.Item>
						</Col>
					) : null}
					<Col span={btnOps.span}>
						{/* 弹窗选择人员等在父组件调用，然后调用表单setFieldsValue方法即可*/}
						<Form.Item
							label={customSelectOption.buttonOption.label}
							rules={[
								{
									required: customSelectOption.required,
									message: `请选择${titleStr || title}`
								}
							]}
							name={dataIndex}
						>
							<Button
								onClick={customSelectOption.buttonOption.onClick}
								style={customSelectOption.isStyle ? {} : btnOps.style}
								type="primary"
								// 不显示输入框则查看 启用按钮
								disabled={formDisabled || customSelectOption.disabled}
							>
								{customSelectOption.buttonOption.text}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			);
		} else if (renderType === 'input') {
			return (
				<Form.Item
					rules={this.buildInputRule(item)}
					{...formItemExtra}
					getValueFromEvent={(event) => {
						let value = event.target.value;
						if (extra && Object.keys(extra).length > 0) {
							if (extra.type === 'number') {
								value = value.slice(0, extra.maxLength);
							}
						}
						return value;
					}}
				>
					<Input placeholder={`请输入${titleStr || title}`} disabled={disabled} {...extra} />
				</Form.Item>
			);
		} else if (renderType === 'select') {
			return (
				<Form.Item rules={[{ required: required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
					<Select
						placeholder={`请选择${titleStr || title}`}
						disabled={disabled}
						getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
						optionFilterProp="label"
						showSearch={dataSource?.length > 8}
						allowClear={required ? false : true}
						{...extra}
						filterOption={(input: any, option: any) => {
							return option['children'].toLowerCase().indexOf(input.toLowerCase()) >= 0;
						}}
					>
						{dataSource.map((it: any) => {
							return (
								<Option key={it.value} value={it.value} disabled={it.disabled}>
									{it.label}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			);
		} else if (renderType === 'textarea') {
			return (
				<Form.Item rules={[{ required: required, message: `请输入${titleStr || title}` }]} {...formItemExtra}>
					<Input.TextArea
						placeholder={`请输入${titleStr || title}`}
						disabled={disabled}
						{...extra}
						maxLength={300}
					/>
				</Form.Item>
			);
		} else if (renderType === 'location') {
			return (
				<Row gutter={8}>
					<Col span={20}>
						<Form.Item rules={[{ required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
							<Input disabled={true} placeholder={`请选择${titleStr || title}`} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Button
							onClick={() => {
								this.handleMapOpen(item);
							}}
							style={{ marginTop: 30 }}
							type="primary"
							{...extra}
						>
							<EnvironmentOutlined />
							定位
						</Button>
					</Col>
				</Row>
			);
		} else if (renderType === 'treeSelect') {
			return (
				<Form.Item rules={[{ required: required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
					<TreeSelect
						style={{ width: '100%' }}
						placeholder={`请选择${titleStr || title}`}
						treeDefaultExpandAll
						dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
						treeData={dataSource}
						multiple={MultiSelect}
						disabled={disabled}
						allowClear={required ? false : true}
						{...extra}
					/>
				</Form.Item>
			);
		} else if (renderType === 'uploadImage') {
			return (
				<Form.Item
					rules={[{ required: required, message: `请选择上传${titleStr || title}` }]}
					{...formItemExtra}
				>
					<ImageUpload
						defaultImgUrl={currentRecord[dataIndex]}
						getUploadImage={this.setUploadImage}
						delimiter={item.delimiter}
						disabled={disabled}
						photoType={photoType === 'capture'}
						photoView={photoView}
						aswSysCode={currentRecord[aswSysCode]}
						plateNoPicUrl={currentRecord[plateNoPicUrl]}
						{...extra}
					/>
				</Form.Item>
			);
		} else if (renderType === 'range') {
			console.log(formItemExtra);
			console.log(extra);
			return (
				<Form.Item rules={[{ required: required, message: `请输入${titleStr || title}` }]} {...formItemExtra}>
					{
						//@ts-ignore
						<RangePicker
							style={{ width: '100%' }}
							disabled={disabled}
							// disabledDate={this.disabledDate}
							disabledDate={(current: any) => {
								return current && current <= moment().endOf('day');
							}}
							showTime={showTime}
							{...extra}
							allowClear={required ? false : true}
						/>
					}
				</Form.Item>
			);
		} else if (renderType === 'datePicker') {
			return (
				<Form.Item rules={[{ required: required, message: `请输入${titleStr || title}` }]} {...formItemExtra}>
					{
						//@ts-ignore
						<DatePicker
							style={{ width: '100%' }}
							placeholder={`请选择${titleStr || title}`}
							disabled={disabled}
							disabledDate={this.disabledDate}
							{...extra}
							allowClear={required ? false : true}
						/>
					}
				</Form.Item>
			);
		} else if (renderType === 'timePicker') {
			return (
				<Form.Item rules={[{ required: required, message: `请输入${titleStr || title}` }]} {...formItemExtra}>
					<TimePicker
						style={{ width: '100%' }}
						placeholder={`请选择${titleStr || title}`}
						disabled={disabled}
						{...extra}
						format={formatTime}
						allowClear={required ? false : true}
					/>
				</Form.Item>
			);
		} else if (renderType === 'radio') {
			return (
				<Form.Item rules={[{ required: required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
					<Radio.Group disabled={disabled} {...extra}>
						{dataSource.map((item: any, index: number) => {
							return (
								<Radio disabled={item.disabled} value={item.value} key={index.toString()} {...extra}>
									{item.label}
								</Radio>
							);
						})}
					</Radio.Group>
				</Form.Item>
			);
		} else if (renderType === 'editor') {
			return (
				<Form.Item rules={[{ required: required, message: `请编辑${titleStr || title}` }]} {...formItemExtra}>
					<IotEditor
						onChange={this.onEditorChange}
						defaultHtml={currentRecord[dataIndex]}
						cRef={this.editorRef}
						style={{ width: 800 }}
					></IotEditor>
				</Form.Item>
			);
		} else if (renderType === 'checkbox') {
			return (
				<Form.Item rules={[{ required: required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
					<Checkbox.Group disabled={disabled}>
						<div className="checkbox-group">
							<div className="checkbox-list">
								{dataSource.map((item: any, index: number) => {
									return (
										<Checkbox className="checkbox-item" key={index} value={item.value}>
											{item.label}
										</Checkbox>
									);
								})}
							</div>
						</div>
					</Checkbox.Group>
				</Form.Item>
			);
		} else if (renderType === 'addressSelect') {
			return (
				<Form.Item rules={[{ required: required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
					<AddressSelect
						areaId={extra.areaId}
						level={extra.level}
						idKey={extra.idKey}
						onChange={this.onAddressChange}
						record={currentRecord}
						clearInput={() => {
							this.setFieldsValue({
								[dataIndex]: undefined
							});
						}}
						disabled={disabled}
					/>
				</Form.Item>
			);
		} else if (renderType === 'Cascader') {
			return (
				<Form.Item rules={[{ required: required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
					<Cascader
						style={{ width: '100%' }}
						placeholder={`请选择${titleStr || title}`}
						disabled={disabled}
						options={dataSource}
						{...extra}
					/>
				</Form.Item>
			);
		} else if (renderType === 'publishScope') {
			return (
				<Form.Item rules={[{ required: required, message: `请选择${titleStr || title}` }]} {...formItemExtra}>
					<PublishScope
						allow={extra && extra.allow}
						selectComplete={(e) => {
							this.publishScopeChange(item, e);
						}}
					/>
				</Form.Item>
			);
		}
	};
	publishScopeChange = (item: any, value: any) => {
		const {
			extra: { allow = ['AGENCY', 'COMPANY', 'OWNER', 'TOURIST'] }
		} = item;
		if (Array.isArray(value)) {
			this.setFieldsValue({
				[item.dataIndex]: value.length > 0 ? value : undefined
			});
		} else {
			const temp = Object.keys(value);
			let flag: number = 0;
			if (Array.isArray(temp)) {
				temp.map((it) => {
					if (value[it].length === 0) {
						flag += 1;
					}
				});
			}
			this.setFieldsValue({
				[item.dataIndex]: flag === allow.length ? undefined : value
			});
		}
	};
	onEditorChange = (editorHtml?: any) => {
		const { currentRecord = {}, formList = [] } = this.props;
		const editorObj: any = formList.find((item: any) => item.renderType === 'editor');
		const { dataIndex = '' } = editorObj;
		const editorContent: any = editorHtml || currentRecord[dataIndex];
		if (dataIndex) {
			this.setFieldsValue({
				[dataIndex]: editorContent
			});
		}
	};
	onAddressChange = (value: any, selectedOptions: any) => {
		const { currentRecord = {}, formList = [] } = this.props;
		const addressObj: any = formList.find((item: any) => item.renderType === 'addressSelect');
		const { dataIndex = '' } = addressObj;
		const addressContent: any = value || currentRecord[dataIndex];
		if (dataIndex) {
			this.setFieldsValue({
				[dataIndex]: addressContent
			});
		}
		this.props.propsChange && this.props.propsChange(selectedOptions);
	};

	setUploadImage = (fileName: string, url: string, name: string, list: any[]) => {
		const imageObj: any = this.props.formList.find((item: any) => item.renderType === 'uploadImage');
		const { dataIndex = '', multiple = false, delimiter } = imageObj;
		let result: any = fileName;
		if (multiple) {
			const arr: string[] = [];
			list.map((item) => {
				arr.push(item.name);
			});
			if (delimiter) {
				result = arr.join(delimiter);
			} else {
				result = arr;
			}
		}
		if (dataIndex) {
			this.setFieldsValue({
				[dataIndex]: result
			});
		}
	};
	handleMapOpen = (item: any) => {
		if (item.checkPart && !this.iotFormRef.current.getFieldValue(item.checkPart)) {
			message.warning('请先选择所属区域');
			return;
		}
		this.iotMapRef.open(this.iotMapRef.initMap);
	};
	handleMapSubmit = ({ location }: LocationDataInterface) => {
		this.setFieldsValue({
			location
		});
		this.props.afterSetLocation && this.props.afterSetLocation(location);
		this.iotMapRef.close();
	};
	disabledDate = (current: any) => {
		return current && current >= moment().endOf('day');
	};
	validateRule = (rule: any, value: any, reg: any, msg: string) => {
		if (value && !reg.test(value)) {
			return Promise.reject(msg);
		} else {
			return Promise.resolve();
		}
	};
	buildInputRule = (item: any) => {
		const { validator = '', required, title } = item;
		const idCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; // 身份证号码校验
		// const phoneReg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/; // 手机号码校验
		const phoneReg = /^(1\d{10})$/;
		const emailReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/; // 邮箱校验
		const chineseReg = /^[0-9a-zA-Z\u0391-\uFFE5?*]{1,}$/; // 只输入汉字，字母，数字
		const intReg = /(^[1-9]\d*$)/; // 只输入正整数
		const _intReg = /(^[0-9]\d*$)/; // 只输入整数包含0
		const blankReg = /^[^\s]*$/; // 禁止输入空格
		const letterReg = /^[a-z0-9]+$/i; // 只输入数字和字母
		const numberReg = /^(\d*\.)?\d+$/; // 只输入数字
		const carNumberRef =
			/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
		const validateLetterOrNum = (rule: any, value: any) => {
			return this.validateRule(rule, value, letterReg, '请输入字母或数字');
		};
		const validateNoSpecial = (rule: any, value: any) => {
			return this.validateRule(rule, value, chineseReg, '请输入汉字、字母、数字');
		};
		const validateIdCard = (rule: any, value: any) => {
			if (ValidateIdCard(value)) {
				return Promise.resolve();
			} else {
				return Promise.reject('请输入正确格式的身份证号');
			}
			// return this.validateRule(rule, value, idCardReg, '请输入正确格式的身份证号');
		};
		const validateEmail = (rule: any, value: any) => {
			return this.validateRule(rule, value, emailReg, '请输入正确格式的邮箱号');
		};
		const validatePhone = (rule: any, value: any) => {
			return this.validateRule(rule, value, phoneReg, '请输入正确格式的手机号');
		};
		const validateInt = (rule: any, value: any) => {
			return this.validateRule(rule, value, intReg, '请输入正整数');
		};
		const _validateInt = (rule: any, value: any) => {
			return this.validateRule(rule, value, _intReg, '请输入整数');
		};
		const validateBlank = (rule: any, value: any) => {
			return this.validateRule(rule, value, blankReg, '禁止输入空格');
		};
		const validateNumber = (rule: any, value: any) => {
			return this.validateRule(rule, value, numberReg, '请输入数字');
		};

		const validateCarNumber = (rule: any, value: any) => {
			return this.validateRule(rule, value, carNumberRef, '请输入有效车牌');
		};
		const resolve = () => Promise.resolve();
		return [
			{ required: required, message: `请输入${title}` },
			{
				validator: validator === 'blank' ? validateBlank : resolve
			},
			{
				validator: validator === 'noSpecial' ? validateNoSpecial : resolve
			},
			{
				validator: validator === 'idCard' ? validateIdCard : resolve
			},
			{
				validator: validator === 'email' ? validateEmail : resolve
			},
			{
				validator: validator === 'phone' ? validatePhone : resolve
			},
			{
				validator: validator === 'int' ? validateInt : resolve
			},
			{
				validator: validator === '_int' ? _validateInt : resolve
			},
			{
				validator: validator === 'letterOrNum' ? validateLetterOrNum : resolve
			},
			{
				validator: validator === 'number' ? validateNumber : resolve
			},
			{
				validator: validator === 'carNumber' ? validateCarNumber : resolve
			}
		];
	};
	render() {
		const {
			rowProps = {},
			colProps = {},
			formOption,
			onValuesChange = () => {},
			formDisabled = false,
			buttonOptions = {
				okText: '确定',
				cancelText: '取消',
				okBtnVisible: true,
				cancelBtnVisible: true,
				style: {}
			},
			layout
		} = this.props;
		return (
			<Fragment>
				<Form
					name="iot-form"
					ref={this.iotFormRef}
					onFinish={this.onFormSubmit}
					className="iot-form"
					autoComplete="off"
					colon={false}
					//@ts-ignore
					onValuesChange={onValuesChange}
					layout={layout || 'vertical'}
					{...formOption}
				>
					<div className="form-item-view">
						<Row gutter={24} {...rowProps}>
							{this.props.formList.map((listItem, index) => {
								const { isShow = true, singleRow = {} } = listItem;
								return isShow ? (
									<Col key={index.toString()} span={24} {...colProps} {...singleRow}>
										{this.renderFormItem(listItem, index)}
									</Col>
								) : null;
							})}
						</Row>
						<div className="extra">{this.props.children}</div>
					</div>
					<div className="iot-form-actions" style={buttonOptions.style}>
						{buttonOptions.cancelBtnVisible ? (
							<Button
								onClick={() => {
									this.onFormCancel();
									this.props.hideModal && this.props.hideModal();
								}}
							>
								{buttonOptions.cancelText}
							</Button>
						) : null}
						{formDisabled && buttonOptions.okBtnVisible ? null : (
							<Button type="primary" htmlType="submit">
								{buttonOptions.okText}
							</Button>
						)}
					</div>
				</Form>
				<LocationModal
					onRef={(ref: any) => (this.iotMapRef = ref)}
					onCancel={this.handleMapSubmit}
					data={this.props.currentRecord}
					mapOption={this.props.mapOption}
					locationSearch={this.props.locationSearch}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: void) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(IotForm);
