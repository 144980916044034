import React from 'react';
import InfoPublish from '@/module/InfoPublish';
import './index.less';
const CompanyServerInfo = (props: any) => {
	return (
		<>
			<InfoPublish {...props} showSearchCtrl={true} />
		</>
	);
};
export default CompanyServerInfo;
