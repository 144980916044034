const phoneApp = {
	getHelpDocDetail: {
		method: 'GET',
		url: '/smartparksysgeneral/v1.0.0/meansmanage/info/'
	},
	iotInformationDetail: {
		method: 'GET',
		url: '/smartpark-app/v1.0.0/app_Information/searchInformationDetail/'
	}
};
export default phoneApp;
