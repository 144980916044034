import React from 'react';
import ModuleIndex from '@/template/ModuleIndex';
import './index.less';
import { Avatar, Image } from 'antd';
import { storage } from '@/utils';
import defaultHeadPath from '@/static/images/impower-person.png';
import { ExportOutlined } from '@ant-design/icons';
import MessageSocket from '@/template/MessageSocket';
const SystemIndex = (props: any) => {
	//@ts-ignore
	const headPath = storage.getSession('login').headPath
		? //@ts-ignore
		  `/permssion/v1.0.0/image/fetch?path=${storage.getSession('login').headPath}`
		: defaultHeadPath;
	//@ts-ignore
	const name = storage.getSession('login').realName;
	const logout = () => {
		storage.clearSession();
		storage.clearLocal();
		const { history } = props;
		history.replace('/login');
	};
	const sysConfig = storage.getSession('sysConfig') as any;
	const domainName = window.location.origin;
	// @ts-ignore
	const appId = '99999999999';

	// @ts-ignore
	const companyId = sysConfig.companyId;
	const prevUrl = `${domainName}/smartparksysgeneral/v1.0.0/file/download?appId=${appId}&companyId=${companyId}&filename=`;
	return (
		<div className="system-page">
			<div className="header">
				<div className="header-left">
					<Image src={prevUrl + sysConfig.platformLogo} width="60px" height="60px" preview={false}></Image>
					<span className="title">{sysConfig.platformName}</span>
				</div>
				<div className="header-right">
					<MessageSocket {...props} />
					<Avatar src={headPath} size="large" />
					<span className="name">{name}</span>
					<ExportOutlined className="icon" onClick={logout} />
				</div>
			</div>
			<ModuleIndex {...props} />
		</div>
	);
};
export default SystemIndex;
