import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import './index.less';
import * as redux from 'react-redux';
import { Button, Checkbox, InputNumber, message, Modal, Select } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { CompanyServer, General } from '@/api';
import { storage } from '@/utils';
const { Option } = Select;
interface Props {
	type: string;
}
const LimitSetting = (props: Props, ref: any) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [timeLimit, setTimeLimit] = useState<any>(null);
	const [timeType, setTimeType] = useState<any>('');
	const [TimeId, setTimeId] = useState<any>(null);
	const [day, setDay] = useState<boolean>(false);
	useEffect(() => {}, [visible]);
	const open = (cb?: Function) => {
		setVisible(true);
		cb && cb();
	};
	const getTimeLimit = () => {
		CompanyServer.getTimeLimit({
			path: [props.type]
		})
			.then((res: IotResponse) => {
				if (res.code === 0 && res.data) {
					setTimeType(res.data.unit);
					setDay(res.data.workday);
					setTimeId(res.data.id);
					setTimeLimit(res.data.dealTimeLimit || null);
				}
			})
			.catch((error: IotResponse) => {});
	};
	const saveTimeSetting = () => {
		if (!timeLimit) {
			message.info('请输入处理时限');
			return;
		}
		let value: AnyObject = {};
		if (TimeId) {
			value['id'] = TimeId;
		}
		value = {
			...value,
			dealTimeLimit: timeLimit,
			unit: timeType,
			workday: day,
			type: props.type
		};
		CompanyServer.saveTimeLimit({
			body: {
				...value
			}
		})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					setVisible(false);
					message.success('处理时限设置成功');
				}
			})
			.catch((error: IotResponse) => {
				message.warning(error.msg);
			});
	};
	const appId = storage.getSession('appId');
	useImperativeHandle(ref, () => {
		return { open, getTimeLimit };
	});
	return (
		<Fragment>
			<Modal
				visible={visible}
				maskClosable={false}
				onCancel={() => {
					setVisible(false);
				}}
				width="600px"
				footer={false}
			>
				<div className="limitTime-success-modal">
					<div className="type-manager-header">
						<div>
							<SettingOutlined />
							<span className="type-manager-header-text">处理时限设置</span>
						</div>
					</div>
					<div className="time_limit_content">
						<span className="time_config_title">*</span>
						<span>处理时限</span>
						<InputNumber
							maxLength={3}
							className="time-limit-input"
							min={1}
							max={100}
							value={timeLimit}
							onChange={(e) => {
								setTimeLimit(e);
							}}
							formatter={(value) => `${value}`}
							parser={(value: any) => value.replace(/[^\d]/g, '')}
						/>
						<Select
							defaultValue="DAY"
							value={timeType}
							style={{ width: 120 }}
							onChange={(e) => {
								setTimeType(e);
							}}
						>
							<Option value="Hour">小时</Option>
							<Option value="DAY">天</Option>
						</Select>
						<Checkbox
							checked={day}
							onChange={(e: any) => {
								setDay(e.target.checked);
							}}
						>
							工作日
						</Checkbox>
					</div>
					<div className="submit-btn">
						<Button type="primary" onClick={saveTimeSetting}>
							保存
						</Button>
					</div>
				</div>
			</Modal>
		</Fragment>
	);
};
export default forwardRef(LimitSetting);
