const Owner = {
	getOwnerList: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/owner/queryPage'
	},
	saveOwner: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/owner/saveParkOwnerInfo'
	},
	editOwner: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/owner/updateParkOwnerInfo'
	},
	deleteOwner: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/owner/del/'
	},
	houseHasOwner: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/owner/checkHomeowner/'
	}
};

export default Owner;
