import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import './index.less';
import * as redux from 'react-redux';
import { SettingOutlined, CloseOutlined, PlusOutlined, CheckCircleTwoTone, CloseCircleFilled } from '@ant-design/icons';
import { message, Modal, Button, Input, Select, Form } from 'antd';
import { BaseServer, General } from '@/api';
import { storage } from '@/utils';
interface Props {
	module: string;
	getTypeList?: Function;
	modalTitle?: string;
	bodyTitle?: string;
	buttonText?: string;
	maxLength?: number;
}
const TypeSetting = (props: Props, ref: any) => {
	const [form] = Form.useForm();
	const [visible, setVisible] = useState<boolean>(false);
	const [tableNode, setTableNode] = useState<any>([]);
	useEffect(() => {
		setTableNode([]);
		form.resetFields();
	}, [visible]);
	const open = () => {
		setVisible(true);
		getSettingList();
	};
	const onFormFinish = (value: any) => {
		const list: any[] = [];
		tableNode.map((item: AnyObject, index: number) => {
			let data: AnyObject = {};
			if (item.id) {
				data['id'] = item.id;
			}
			data = {
				...data,
				contactInfo: value[`contactInfo${index}`],
				tradeName: value[`tradeName${index}`],
				workDate: value.workDate,
				module: 0
			};
			list.push(data);
		});
		BaseServer.saveType({
			body: list
		})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					message.success('添加成功');
					setVisible(false);
				}
			})
			.catch((error: IotResponse) => {
				message.success('添加失败');
				setVisible(false);
			});
	};
	const getSettingList = () => {
		BaseServer.typeList({
			body: { module: 0, pageNum: 1, pageSize: 99999 }
		})
			.then((res: IotResponse) => {
				if (res.code === 0 && res.data.list.length > 0) {
					const data: any[] = [];
					const dataList: AnyObject = {};
					res.data.list.map((item: AnyObject, index: number) => {
						data.push({
							id: item.id
						});
						dataList[`tradeName${index}`] = item.tradeName;
						dataList[`contactInfo${index}`] = item.contactInfo;
					});
					form.setFieldsValue({
						workDate: res.data.list[0].workDate
					});
					setTableNode(data);
					form.setFieldsValue({ ...dataList });
				}
			})
			.catch((error: IotResponse) => {});
	};
	const appId = storage.getSession('appId');
	const deleteType = (record: AnyObject, index: number) => {
		const dataList = [...tableNode];
		if (record.id) {
			BaseServer.deleteType({
				body: [record.id]
			})
				.then((res: IotResponse) => {
					if (res.code === 0) {
						message.success('删除成功');
						getSettingList();
					} else {
						message.warning('删除失败');
					}
				})
				.catch((error: IotResponse) => {
					message.warning(error.msg);
				});
		} else {
			dataList.splice(index, 1);
		}
		setTableNode(dataList);
	};
	useImperativeHandle(ref, () => {
		return { open };
	});
	const renderTab = () => {
		const node: JSX.Element[] = [];
		tableNode.forEach((item: any, index: number) => {
			node.push(
				<div className="type-table" key={index}>
					<div className="cell">
						<Form.Item name={`tradeName${index}`} rules={[{ required: true, message: '请输入部门名称' }]}>
							<Input placeholder="请输入部门名称" maxLength={10} autoComplete="off" />
						</Form.Item>
					</div>
					<div className="cell">
						<Form.Item name={`contactInfo${index}`} rules={[{ required: true, message: '请输入联系方式' }]}>
							<Input placeholder="请输入联系方式" maxLength={20} autoComplete="off" />
						</Form.Item>
					</div>
					<div
						className="delete-icon"
						onClick={() => {
							deleteType(item, index);
						}}
					>
						<CloseCircleFilled />
					</div>
				</div>
			);
		});
		return node;
	};
	return (
		<Fragment>
			<Modal
				visible={visible}
				maskClosable={false}
				closable={false}
				width="600px"
				wrapClassName="connect-type-manager-modal"
				footer={false}
			>
				<div className="incept-success-modal">
					<div className="type-manager-header">
						<div>
							<SettingOutlined />
							<span className="type-manager-header-text">联系方式设置</span>
						</div>
						<CloseOutlined
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setVisible(false);
							}}
						/>
					</div>
					<Form form={form} onFinish={onFormFinish}>
						<div className="type-manager-body">
							<div className="answer_time">
								<div className="answer_time_cell">
									<span className="icon">*</span>
									接听时间
								</div>
								<Form.Item name="workDate" rules={[{ required: true, message: '请输入接听时间' }]}>
									<Input placeholder="请输入接听时间" maxLength={50} autoComplete="off" />
								</Form.Item>
							</div>
							<div className="check-type-manager-body-content">
								<div className="type-table-left">
									<div className="type-table" style={{ paddingBottom: '15px' }}>
										<div className="cell">
											部门名称
											<span className="icon">*</span>
										</div>
										<div className="cell">
											联系方式
											<span className="icon">*</span>
										</div>
									</div>
									<div className="type-table-body">{renderTab()}</div>
								</div>
								<Button
									type="primary"
									icon={<PlusOutlined />}
									onClick={() => {
										setTableNode([...tableNode, tableNode.length + 1]);
										form.setFieldsValue({
											[`contactInfo${tableNode.length}`]: null,
											[`tradeName${tableNode.length}`]: ''
										});
									}}
								>
									添加
								</Button>
							</div>
						</div>
						<div className="submit-btn">
							<Form.Item>
								<Button type="primary" htmlType="submit">
									保存
								</Button>
							</Form.Item>
						</div>
					</Form>
				</div>
			</Modal>
		</Fragment>
	);
};
export default forwardRef(TypeSetting);
