const Area = {
	addArea: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/parkarea/addArea'
	},
	editArea: {
		method: 'PUT',
		url: '/smartparkbasedata/v1.0.0/parkarea/editArea'
	},
	deleteArea: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/parkarea/deleteArea/'
	},
	editImportType: {
		method: 'PUT',
		url: '/smartparkbasedata/v1.0.0/parkarea/editImportType/'
	},
	editLocations: {
		method: 'PUT',
		url: '/smartparkbasedata/v1.0.0/parkarea/editLocations/'
	},
	editResponsiblePerson: {
		method: 'PUT',
		url: '/smartparkbasedata/v1.0.0/parkarea/editResponsiblePerson/'
	},
	listAreas: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/parkarea/listAreas'
	},
	treeArea: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/parkarea/treeArea'
	},
	addType: {
		method: 'POST',
		url: '/smartparksysgeneral/v1.0.0/commonType/addCommonType'
	},
	getType: {
		method: 'GET',
		url: '/smartparksysgeneral/v1.0.0/commonType/getCommonType'
	},
	delType: {
		method: 'POST',
		url: '/smartparksysgeneral/v1.0.0/commonType/deleteCommonType/'
	},
	getAllParkAreaLocations: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/parkarea/getAllParkAreaLocations'
	},
	getAreaDetail: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/parkarea/areaDetail/'
	},
	resetLocations: {
		method: 'PUT',
		url: '/smartparkbasedata/v1.0.0/parkarea/resetLocations/'
	},
	getAreaTreeByType: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/parkarea/treeArea'
	},
	getUnitInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/unit/info/'
	}
};

export default Area;
