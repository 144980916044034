const cardinalInfo = {
	getCardinalInfo: {
		method: 'POST',
		url: '/smartparksysgeneral/v1.0.0/cardinalinfo/list'
	},
	addCardinalInfo: {
		method: 'POST',
		url: '/smartparksysgeneral/v1.0.0/cardinalinfo/save'
	},
	deleteCardinalInfo: {
		method: 'DELETE',
		url: '/smartparksysgeneral/v1.0.0/cardinalinfo/'
	}
};
export default cardinalInfo;
