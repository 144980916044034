const companyServer = {
	saveTimeLimit: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/timelimit/save'
	},
	getTimeLimit: {
		method: 'GET',
		url: '/smartbfbback/v1.0.0/timelimit/getTime/'
	}
};
export default companyServer;
