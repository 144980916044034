const task = {
	getTaskList: {
		method: 'GET',
		url: '/repairorder/v1.0.0/history/getHistoryTaskList'
	},
	getUpcomingTasksList: {
		method: 'POST',
		url: '/repairorder/v1.0.0/history/getHistoryTaskList'
	},
	getNotificationTask: {
		method: 'POST',
		url: '/repairorder/v1.0.0/history/getHsitoryProcessInstanceList'
	},
	getDeploymentList: {
		method: 'GET',
		url: '/repairorder/v1.0.0/deployment/getDeploymentList'
	},
	getTackTotal: {
		method: 'POST',
		url: '/repairorder/v1.0.0/statistic/statsticVarinstGroupCount'
	}
};
export default task;
