import React, { Component } from 'react';
import { Input, Button, Modal, message } from 'antd';
import './index.less';
import { SearchOutlined } from '@ant-design/icons';
import { Props, State } from './interface';
import { BaseData } from '@/api';

export interface LocationDataInterface {
	location: string;
	longitude: string;
	latitude: string;
	addressText?: string;
}
class IotMap extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			keyword: '',
			selectedAddress: '',
			visible: false,
			selectPos: '',
			thisMap: null,
			longitude: '',
			latitude: '',
			showAddressText: props.showAddressText || false,
			marker: null
		};
	}

	placeSearch: any = null;
	componentDidMount() {
		this.props.onRef(this);
	}
	//绘制多边形
	drawPolygon = (locations: any[], options: any, id: string, textOption?: any) => {
		const { thisMap } = this.state;
		const paths: any[] = [];
		locations.map((item: any) => {
			paths.push([item.longitude, item.latitude]);
		});
		const polygon = new window.AMap.Polygon({
			path: paths,
			...options
		});
		polygon.setExtData({
			areaId: id
		});

		// 创建纯文本标记
		const text = new window.AMap.Text({
			text: textOption.name,
			anchor: 'center', // 设置文本标记锚点
			draggable: false,
			cursor: 'pointer',
			// angle: 10,
			style: {
				padding: '.75rem 1.25rem',
				'margin-bottom': '1rem',
				'border-radius': '.25rem',
				'background-color': 'transparent',
				'border-width': 0,
				// 'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
				'text-align': 'center',
				'font-size': '14px',
				color: textOption.textColor || 'blue',
				...textOption.style
			},
			position: [locations[0].longitude, locations[0].latitude]
		});
		thisMap.add(text);
		thisMap.add(polygon);
		return polygon;
	};
	initMap = () => {
		setTimeout(() => {
			//@ts-ignore
			if (this.refMap) {
				//@ts-ignore
				const map = new AMap.Map('map', {
					resizeEnable: true
				});
				// map.setMapStyle('amap://styles/darkblue');
				map.on('click', (e: any) => {
					const { mapOption = {} } = this.props;

					if (mapOption.readonly) {
						return;
					}
					if (mapOption.areaId) {
						message.warn('请在指定区域内打点');
						return;
					}
					this.drawMaker('', e);
				});
				this.setState({ thisMap: map }, () => {
					const { thisMap } = this.state;
					const { mapOption } = this.props;
					if (mapOption) {
						if (mapOption.areaId) {
							BaseData.getAllParkAreaLocations({}).then((res: IotResponse) => {
								if (res.code === 0) {
									const crtArea = res.data.find((item: any) => item.id === mapOption.areaId);
									//绘制当前区域
									if (crtArea) {
										const { locations } = crtArea;
										if (Array.isArray(locations) && locations.length > 0) {
											const option = {
												strokeColor: '#ff0000',
												fillColor: 'transparent', // 多边形填充颜色
												borderWeight: 2,
												zIndex: 100
											};
											const currentPolygon = this.drawPolygon(locations, option, crtArea.id, {
												name: crtArea.name
											});
											currentPolygon.on('click', (e: any) => {
												const { mapOption } = this.props;

												if (mapOption.readonly) {
													return;
												}
												this.drawMaker('', e);
											});
										}
										//绘制父级区域
										if (crtArea.parentAreaId) {
											const parentArea = res.data.find(
												(item: any) => item.id === crtArea.parentAreaId
											);
											if (parentArea) {
												const { locations } = parentArea;
												if (Array.isArray(locations) && locations.length > 0) {
													const option = {
														strokeColor: 'yellow',
														fillColor: 'transparent', // 多边形填充颜色
														borderWeight: 2,
														zIndex: 80
													};
													// this.drawPolygon(locations, option, parentArea.id, {
													// 	name: parentArea.name
													// });
												}
												const brotherAreas = res.data.filter((item: any) => {
													return (
														item.parentAreaId &&
														item.parentAreaId === parentArea.id &&
														item.id !== crtArea.id
													);
												});
												if (brotherAreas.length > 0) {
													brotherAreas.map((item: any) => {
														const { locations } = item;
														if (Array.isArray(locations) && locations.length > 0) {
															const option = {
																strokeColor: 'blue',
																fillColor: 'transparent', // 多边形填充颜色
																borderWeight: 2,
																zIndex: 60
															};
															// this.drawPolygon(locations, option, item.id, {
															// 	name: item.name
															// });
														}
													});
												}
											}
										}
										thisMap.setFitView();
									}
								}
							});
						}
					}
					const data = this.props.data;
					if (data && data.longitude && data.latitude) {
						this.drawMaker('init', data);
					} else {
						map.setZoomAndCenter(12, ['108.851042', '34.399979']);
					}
				});
				//@ts-ignore
				AMap.service(['AMap.PlaceSearch'], () => {
					//构造地点查询类
					//@ts-ignore
					const placeSearch = new AMap.PlaceSearch({
						pageSize: 5,
						pageIndex: 1,
						map: map, // 展现结果的地图实例
						autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
					});
					this.placeSearch = placeSearch;
				});
			}
		}, 200);
	};

	handleCancel = (e: any) => {
		const { selectPos, longitude, latitude, selectedAddress } = this.state;
		this.setState({ selectPos: '', latitude: '', longitude: '' });
		this.props.onCancel({
			location: selectPos,
			longitude,
			latitude,
			addressText: selectedAddress
		});
	};

	drawMaker = (type: string, data: any) => {
		const map = this.state.thisMap;
		// map.clearMap();
		if (this.state.marker) {
			map.remove(this.state.marker);
		}
		let longitude, latitude;
		if (type === 'init') {
			longitude = data.longitude;
			latitude = data.latitude;
		} else {
			longitude = data.lnglat.getLng();
			latitude = data.lnglat.getLat();
		}
		const location = `${longitude},${latitude}`;

		this.setState({ selectPos: location, longitude, latitude });
		const iconUrl = '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png';

		//@ts-ignore
		const marker = new AMap.Marker({
			//@ts-ignore
			icon: new AMap.Icon({
				image: iconUrl,
				//@ts-ignore
				size: new AMap.Size(30, 30), //图标大小
				//@ts-ignore
				imageSize: new AMap.Size(30, 30)
			}),
			position: [longitude, latitude]
		});

		// marker.setLabel({
		// 	//@ts-ignore
		// 	offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
		// 	content: `<div class='map-label-info'>${location}</div>`, //设置文本标注内容
		// 	direction: 'right' //设置文本标注方位
		// });
		map.add(marker);
		this.setState({
			marker
		});
		map.setCenter([longitude, latitude]);
		this.getAddressByPoi(longitude, latitude);
	};

	//@ts-ignore
	searchch = ({ target: { value } }) => {
		this.setState({ keyword: value });
	};

	getAddressByPoi = (lng: number, lat: number) => {
		fetch(
			`https://restapi.amap.com/v3/geocode/regeo?key=d4858704991659b52fedddc3a19845cb&location=${lng},${lat}&poitype=&radius=1000&extensions=all&batch=false&roadlevel=0`,
			{ method: 'POST' }
		)
			.then((res: any) => {
				if (res) {
					return res.json();
				}
			})
			.then((res: any) => {
				if (res) {
					const addrObj = res.regeocode;
					if (addrObj) {
						this.setState({ selectedAddress: addrObj.formatted_address });
					}
				}
			});
	};
	searchPos = () => {
		const { keyword } = this.state;
		this.placeSearch.search(keyword);
	};
	open = (cb: Function) => {
		this.setState(
			{
				visible: true
			},
			() => {
				cb && cb();
			}
		);
	};
	close = () => {
		this.setState({
			visible: false
		});
	};
	render() {
		const { keyword, selectPos, showAddressText } = this.state;
		const { locationSearch = false, showSearch } = this.props;
		return (
			<Modal
				title={false}
				visible={this.state.visible}
				className="com-modal location-modal"
				onOk={this.close}
				onCancel={this.handleCancel}
				footer={false}
			>
				<div
					ref={(ref) => {
						//@ts-ignore
						this.refMap = ref;
					}}
					id="map"
					style={{ width: '100%', height: '100%' }}
				/>
				{showAddressText ? <div className="selected-address-text">{this.state.selectedAddress}</div> : null}
				<div className="modal-bottom">
					{showSearch ? (
						<div className={`modal-bottom-left ${locationSearch ? 'modal-bottom-no' : ''}`}>
							<Input
								placeholder="输入地点名称查找"
								value={keyword}
								className="search-text-input"
								onChange={this.searchch}
							/>
							<Button className="search-btn" type="primary" disabled={!keyword} onClick={this.searchPos}>
								<SearchOutlined />{' '}
							</Button>
						</div>
					) : (
						<span />
					)}
					<Input className="pos-show-input" readOnly value={selectPos} />
				</div>
			</Modal>
		);
	}
}

export default IotMap;
