import React, { useState, useRef, useEffect } from 'react';
import { Popconfirm, Table, DatePicker, Input, message } from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import './index.less';
import IotButton from '@/components/IotButton';
import { ModalStatus, IExternalLinksItem, IQueryparam } from './interface';
import { Permission } from '../../api';
import IotModal from '@/components/IotModal';
import IotForm from '@/components/IotForm';
import ErrorTemp from '@/template/ErrorTemp';

import moment from 'moment';
const modalStatusText: Record<ModalStatus, string> = {
	add: '新增',
	edit: '编辑',
	preview: '查看'
};
const staticExternalLinksParam = {
	type: 'externalLinks',
	key: 'externalLinks',
	userId: 'bfb'
};
const externalLinksTypes = [
	{ label: '请选择', value: '' },
	{ label: '调查问卷', value: 'questionnaire' },
	{ label: '企业课堂', value: 'classTang' }
];
const { RangePicker } = DatePicker;
const UniConfig = () => {
	const modalRef = useRef<any>();
	const formRef = useRef<any>();
	const columns = [
		{
			title: '标题',
			dataIndex: 'title',
			width: '18%',
			render: (text: string) => <div className="text-align_center">{text}</div>
		},
		{
			title: '地址',
			dataIndex: 'path',
			width: 170,
			render: (text: string) => <div className="text-align_center">{text}</div>
		},
		{
			title: 'appId',
			dataIndex: 'appId',
			width: 180,
			render: (text: string) => <div className="text-align_center">{text}</div>
		},
		{
			title: '功能菜单',
			dataIndex: 'type',
			width: '12%',
			render: (text: string) => (
				<div className="text-align_center">{externalLinksTypes.find((i) => i.value === text)?.label}</div>
			)
		},
		{
			title: '发布时间',
			dataIndex: 'createTime',
			width: '15%',
			render: (text: string) => <div className="text-align_center">{text}</div>
		},
		{
			title: '操作',
			dataIndex: 'actions',
			render: (_text: any, record: IExternalLinksItem) => {
				return (
					<div className="iot-opt-view" key="opt">
						<span onClick={() => openModal('edit', record)}>编辑</span>
						<span onClick={() => openModal('preview', record)}>查看</span>
						<Popconfirm
							placement="bottom"
							title="确认是否删除 ？"
							onConfirm={() => {
								handleDelete(record);
							}}
							okText="是"
							cancelText="否"
						>
							删除
						</Popconfirm>
					</div>
				);
			},
			width: '18%'
		}
	];
	const [dataSource, setDataSource] = useState<IExternalLinksItem[]>([]);
	const [dataSourceShow, setDataSourceShow] = useState<IExternalLinksItem[]>([]);
	const [modalStatus, setModalStatus] = useState<ModalStatus>('add');
	const [crtRecord, setCrtRecord] = useState<IExternalLinksItem>();
	const [canSubmit, setCanSubmit] = useState(false);
	const formList = [
		{
			renderType: 'input',
			title: '标题',
			dataIndex: 'title',
			required: true,
			extra: {
				maxLength: 100
			}
		},
		{
			renderType: 'input',
			title: '链接地址',
			dataIndex: 'path',
			required: true,
			extra: {
				maxLength: 100
			}
		},
		{
			renderType: 'input',
			title: 'appId',
			dataIndex: 'appId',
			required: true
		},
		{
			renderType: 'select',
			title: '功能菜单',
			dataIndex: 'type',
			required: true,
			dataSource: externalLinksTypes,
			extra: {
				defaultValue: ''
			}
		}
	];
	const openModal = (modalStatus: ModalStatus, record?: IExternalLinksItem) => {
		setModalStatus(modalStatus);
		setCanSubmit(true);
		modalRef.current?.open(() => {
			if (modalStatus === 'add') return;
			if (modalStatus === 'edit') setCrtRecord(record);
			formRef.current?.setFieldsValue(record);
		});
	};
	const closeModal = () => {
		modalRef.current?.close();
		formRef.current?.onFormCancel();
	};
	const formSubmit = (values: IExternalLinksItem) => {
		modalStatus === 'add' && handleAdd(values);
		modalStatus === 'edit' && handleEdit(values);
	};
	const handleAdd = async (values: IExternalLinksItem) => {
		if (!canSubmit) return;
		setCanSubmit(false);
		const key = `${new Date().getTime()}_${Math.floor(Math.random() * 9999 + 1000)}`;
		const createTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		let res;
		if (!dataSource.length) {
			res = await Permission.postUserParam({
				body: {
					...staticExternalLinksParam,
					param: [{ ...values, createTime, key, linkType: 'mini' }]
				}
			});
		} else {
			res = await Permission.putUserParam({
				body: {
					id: paramId,
					...staticExternalLinksParam,
					param: [...dataSource, { ...values, createTime, key, linkType: 'mini' }]
				}
			});
		}
		setCanSubmit(true);
		if (!res.code) {
			message.success('新增成功');
		} else {
			message.error('新增失败');
		}
		closeModal();
		handleQuery();
	};
	const handleEdit = async (values: IExternalLinksItem) => {
		if (!canSubmit) return;
		setCanSubmit(false);
		const datSourceTemp = dataSource.map((item) => {
			const { createTime, key } = item;
			const obj = {
				...(item.key === crtRecord?.key ? values : item),
				createTime,
				key
			};
			return obj;
		});
		const res = await Permission.putUserParam({
			body: {
				id: paramId,
				...staticExternalLinksParam,
				param: [...datSourceTemp]
			}
		});
		setCanSubmit(true);
		if (!res.code) {
			message.success('更新成功');
		} else {
			message.error('更新失败');
		}
		closeModal();
		handleQuery();
	};
	const handleDelete = async (record: IExternalLinksItem) => {
		const datSourceTemp = dataSource.filter((item) => item.key !== record.key);
		const res = await Permission.putUserParam({
			body: {
				id: paramId,
				...staticExternalLinksParam,
				param: datSourceTemp
			}
		});
		if (!res.code) {
			message.success('删除成功');
		} else {
			message.error('删除失败');
		}
		handleQuery();
	};
	const queryParam = useRef<IQueryparam>({
		timeRangeValue: ['', ''],
		titleKey: ''
	});

	const handleQuery = async () => {
		let dataTemp = await getDataSource();
		const { timeRangeValue, titleKey } = queryParam.current;
		if (timeRangeValue[0]) {
			const [creatBefore, createAfter] = timeRangeValue;
			dataTemp = dataTemp.filter(
				(item) =>
					new Date(item.createTime).getTime() >= new Date(creatBefore).getTime() &&
					new Date(item.createTime).getTime() <= new Date(createAfter).getTime()
			);
		}
		dataTemp = dataTemp.filter((item) => item.title.includes(titleKey.trim()));
		if (!dataTemp.length) setErrorTempConfig('errnodata');
		dataTemp = dataTemp.sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime());
		setDataSourceShow(dataTemp);
	};
	const handleRefresh = () => {
		setDisabledResetBtn(true);
		queryParam.current = {
			timeRangeValue: ['', ''],
			titleKey: ''
		};
		handleQuery();
	};
	const [errorTempConfig, setErrorTempConfig] = useState('errnodata');
	const [disabledResetBtn, setDisabledResetBtn] = useState(true);
	const [pageSize, setPageSize] = useState(20);
	const [current, setCurrent] = useState(1);
	const [paramId, setParamId] = useState(0);
	const getDataSource = async () => {
		const dataTemp: IExternalLinksItem[] = [];
		setErrorTempConfig('pageloading');
		const res = await Permission.getUserParam({ query: staticExternalLinksParam });
		if (!res.code) {
			const { data = [] } = res;
			if (!data.length) setErrorTempConfig('errnodata');
			data.forEach((dataItem: { key: string; type: string; id: number; param: IExternalLinksItem[] }) => {
				dataItem.key === 'externalLinks' &&
					dataItem.type === 'externalLinks' &&
					dataTemp.push(...dataItem.param) &&
					setParamId(dataItem.id);
			});
		} else {
			setErrorTempConfig('err500');
		}
		setDataSource(dataTemp);
		return dataTemp;
	};
	useEffect(() => {
		handleQuery();
	}, []);
	return (
		<div className="uni-config-page">
			<div className="uni-config-page-content">
				<IotModal
					onRef={(ref: any) => (modalRef.current = ref)}
					className="uniConfigModal"
					title={modalStatusText[modalStatus]}
					footer={null}
					afterClose={closeModal}
					onCancel={closeModal}
				>
					<IotForm
						formList={formList}
						onRef={(ref: any) => (formRef.current = ref)}
						formDisabled={modalStatus === 'preview'}
						onSubmit={formSubmit}
						hideModal={closeModal}
					/>
				</IotModal>
				<div className="main-body">
					<div className="search-box">
						<div className="search-container">
							<div className="search-content">
								<div className="title-search-item">
									<span className="label">发布时间</span>
									{
										//@ts-ignore
										<RangePicker
											allowClear
											showTime
											onChange={(dates: any, dateStrings: [string, string]) => {
												setDisabledResetBtn(!dateStrings[0]);
												queryParam.current = {
													...queryParam.current,
													timeRangeValue: dateStrings
												};
											}}
										/>
									}
								</div>
								<div className="title-search-item">
									<span className="label">标题</span>
									<Input
										allowClear
										value={queryParam.current.titleKey}
										placeholder="请输入标题"
										onChange={(e) => {
											setDisabledResetBtn(!e.target.value.trim());
											queryParam.current = {
												...queryParam.current,
												titleKey: e.target.value.trim()
											};
										}}
									/>
								</div>
							</div>
						</div>
						<div className="search-container">
							<div className="search-btn">
								<IotButton onClick={handleQuery}>
									<SearchOutlined style={{ fontSize: '13px' }} /> 查询
								</IotButton>
								<IotButton disabled={disabledResetBtn} onClick={handleRefresh}>
									<ReloadOutlined style={{ fontSize: '13px', marginRight: '5px' }} />
									重置
								</IotButton>
							</div>
						</div>
					</div>
					<div className="table-box">
						<div className="action-box">
							<IotButton onClick={() => openModal('add')}>新增</IotButton>
						</div>
						<div className="table-container">
							<div className="table-content">
								<Table
									scroll={{
										x: 982,
										y: window.innerHeight - 444,
										scrollToFirstRowOnChange: true
									}}
									tableLayout="fixed"
									columns={columns}
									dataSource={dataSourceShow}
									pagination={{
										total: dataSourceShow.length,
										showTotal: (total) => (
											<div>
												共<span style={{ color: '#fd8820' }}>{total}</span>条
											</div>
										),
										showSizeChanger: true,
										showQuickJumper: true,
										pageSize,
										current,
										onChange: (current, pageSize?: number) => {
											setCurrent(current);
											pageSize && setPageSize(pageSize);
										}
									}}
									locale={{
										emptyText: (
											<div className="error-temp-box">
												<ErrorTemp type={errorTempConfig} customScale={0.5}></ErrorTemp>
											</div>
										)
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default UniConfig;
