const House = {
	getHouseList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/house/page'
	},
	addHouse: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/house/save'
	},
	updHouse: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/house/update'
	},
	deleteHouse: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/house/'
	},
	getHouseByOther: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/house/getHouseByOther'
	},
	hasOwnerByHouse: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/owner/checkHomeowner/'
	}
};

export default House;
