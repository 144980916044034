import React, { Component } from 'react';
import { storage } from './utils';
import { Props, State } from './AppTypes';
import * as redux from 'react-redux';
import { renderRoutes } from 'react-router-config';
import './App.less';

const { connect } = redux;
const route = {
	routes: [
		{
			alias: '系统首页',
			path: '/systemIndex'
		},
		{
			alias: '个人首页',
			path: '/userIndex'
		}
	]
};
class App extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			unReadCount: 0,
			userName: '',
			total: 0,
			tasksCount: 0,
			backendName: '',
			logoImage: '',
			companyId: ''
		};
	}

	componentDidMount() {
		this.initIndexPage();
	}
	initIndexPage = () => {
		const indexPage = storage.getLocal('indexPagePath');
		// const { route } = this.props;
		if (!indexPage) {
			storage.setLocal('indexPagePath', route.routes[0].path);
		}
		this.isLogin();
	};
	isLogin = () => {
		const { history } = this.props;
		if (!storage.getSession('appId')) {
			history.replace('/login');
			return;
		}
		const loginInfo = storage.getSession('login');
		this.setState({
			//@ts-ignore
			userName: loginInfo.realName
		});
		if (this.props.location.pathname === '/') {
			history.replace(storage.getLocal('indexPagePath'));
		}
	};
	logout = () => {
		storage.clearLocal();
		storage.clearSession();
		const { location } = window;
		location.href = decodeURI(`${location.protocol}//${location.host}/#/login`);
	};
	toggleIndexPage = () => {
		const { history } = this.props;
		const crtPath = this.getNextIndexPageRoute().path;
		history.replace(crtPath);
		storage.setLocal('indexPagePath', crtPath);
	};
	getNextIndexPageRoute = () => {
		// const { route } = this.props;
		const indexPage = storage.getLocal('indexPagePath');
		const index = route.routes.findIndex((item: any) => item.path === indexPage);
		let result: { path: string; alias: string };
		if (index + 1 >= route.routes.length) {
			result = route.routes[0];
		} else {
			result = route.routes[index + 1];
		}
		return result;
	};
	go2Index = () => {
		const indexPage = storage.getLocal('indexPagePath');
		this.props.history.replace(indexPage);
	};

	render() {
		return <div className="app-page">{renderRoutes(this.props.route.routes)}</div>;
	}
}
const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: void) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
