import React, { useEffect, useRef, useImperativeHandle } from 'react';
import E from 'wangeditor';
import { storage } from '../../utils';
import { Props } from './interface';
import { message } from 'antd';
import './index.less';
const Editor: React.FC<any> = (props: Props) => {
	const editorElement = useRef<any>();
	const editor = useRef();
	const requestCount = useRef<any>();
	useEffect(() => {
		onEditorCreate();
	}, []);
	const onEditorCreate = () => {
		const { imgFileSize = 50, videoFileSize = 200 } = props;
		//@ts-ignore
		editor.current = new E(editorElement.current);
		//@ts-ignore
		editor.current.config = {
			//@ts-ignore
			...editor.current.config,
			showFullScreen: true,
			focus: false,
			excludeMenus: ['emoticon'],
			uploadImgShowBase64: true,
			uploadImgMaxSize: imgFileSize * 1024 * 1024,
			uploadImgTimeout: 500000,
			uploadVideoMaxSize: videoFileSize * 1024 * 1024,
			uploadImgAccept: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
			uploadVideoAccept: ['mp4'],
			customUploadImg: (resultFiles: any, insertImgFn: any) => {
				uploadFile(resultFiles, 'image')
					.then((value: any) => {
						insertImgFn(value);
					})
					.catch((err) => {
						message.warning(err);
					});
			},
			customUploadVideo: (resultFiles: any, insertVideoFn: any) => {
				uploadFile(resultFiles, 'video')
					.then((value: any) => {
						insertVideoFn(value);
					})
					.catch((err) => {
						message.warning(err);
					});
			},
			onchange: (html: any) => {
				props.onChange(html);
			}
		};
		//@ts-ignore
		editor.current.create();
	};

	useImperativeHandle(props.cRef, () => ({
		clear: () => {
			//@ts-ignore
			editor.current.txt.clear();
		},
		setHtml: () => {
			//@ts-ignore
			editor.current.txt.html(props.defaultHtml);
		}
	}));

	useEffect(() => {
		if (props.defaultHtml) {
			//@ts-ignore
			editor.current.txt.html(props.defaultHtml);
		}
	}, [props.defaultHtml]);

	const uploadFile = (file: any, type: string = 'image') => {
		return new Promise((resolve, reject) => {
			let xhr: any = null;
			let formData: any = null;
			const token = storage.getSession('token');
			const pwd = storage.getSession('appKey') || '';
			const appId = storage.getSession('appId') || '';
			// @ts-ignore
			const companyId = storage.getSession('login').companyId;
			xhr = new XMLHttpRequest();
			xhr.withCredentials = false;
			xhr.open('POST', '/smartparksysgeneral/v1.0.0/file/upload');
			xhr.setRequestHeader('appKey', pwd);
			xhr.setRequestHeader('appId', appId);
			xhr.setRequestHeader('iot-token', token);
			xhr.onload = function () {
				let json: AnyObject = {};
				if (xhr.status !== 200) {
					reject('HTTP Error: ' + xhr.status);
					return;
				}
				json = JSON.parse(xhr.responseText);
				if (json.code === -10020) {
					const headers = xhr.getAllResponseHeaders();
					requestCount.current += 1;
					if (requestCount.current > 5) {
						reject('请求次数过多');
						return;
					}
					storage.setSession('iot-token', headers['iot-token']);
					uploadFile(file, type);
					return;
				}

				if (json.code === 0) {
					const fileUrl = `/smartparksysgeneral/v1.0.0/file/download?appId=${appId}&companyId=${companyId}&filename=${json.data}`;
					resolve(fileUrl);
				} else {
					reject(json.msg);
				}
			};
			formData = new FormData();
			formData.append('file', file[0], `${new Date().getTime()}_${file[0].name}`); //此处与源文档不一样
			formData.append('companyId', companyId);
			xhr.send(formData);
		});
	};

	return (
		<div className="wang-editor" style={props.style}>
			<div className="text-area" ref={editorElement}></div>
		</div>
	);
};

export default Editor;
