import Area from './area';
import Buildings from './buildings';
import Element from './element';
import Org from './org';
import House from './house';
import Enterprise from './enterprise';
import AreaBaseInfo from './areabaseinfo';
import ManagePeople from './managePeople';
import DenyPeople from './denyPeople';
import DenyCar from './denyCar';
import CarManage from './carManage';
import PeopleManage from './peopleManage';
import JobApi from './jobs';
import Owner from './owner';
import Employee from './employee';
import groupManage from './groupManage';

const BaseData = {
	...Area,
	...Buildings,
	...Element,
	...House,
	...Org,
	...Enterprise,
	...AreaBaseInfo,
	...ManagePeople,
	...DenyPeople,
	...DenyCar,
	...CarManage,
	...PeopleManage,
	...JobApi,
	...Owner,
	...Employee,
	...groupManage
};

export default BaseData;
