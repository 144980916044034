import React, { Component } from 'react';
import './index.less';
import * as redux from 'react-redux';
import { Cascader, Input } from 'antd';
import { BaseData } from '@/api';
import { getAreaTreeByType, getFlatTreeData } from '@/utils';

const { connect } = redux;
interface Props {
	areaId: any;
	level?: string; //级别  unit ｜ 单元  floor | 楼层 house |房屋
	record?: any; // [楼宇ID，单元ID, 楼层, 房屋ID]
	onChange: Function;
	clearInput?: Function;
	disabled?: boolean;
	idKey?: string;
}
interface State {
	treeData: any[];
	selectOption: any[];
	selectValue: any[];
	treeList: any[];
	areaId: any;
	unitId: any;
	buildingId: any;
	showSelect: boolean;
	defaultValue: string;
	idKey: string;
}

class AddressSelect extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			treeData: [],
			selectOption: [],
			selectValue: [],
			areaId: '',
			unitId: '',
			buildingId: '',
			showSelect: true,
			defaultValue: '',
			idKey: props.idKey || 'value',
			treeList: []
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props, nextState: State) {
		if (nextProps.areaId !== this.props.areaId) {
			const { areaId } = nextProps;
			this.onSelectFill(areaId);
		}
		// if (Object.keys(nextProps.record).length > 0) {
		// 	this.handleDefaultValue();
		// }
	}

	handleDefaultValue = () => {
		const { treeList } = this.state;
		const { record } = this.props;
		const { build, unit, floor, house } = record;
		let buildDisplay, unitDisplay, floorDisplay, houseDisplay;
		const { idKey = 'value' } = this.props;
		treeList.map((item) => {
			if (item[idKey] == build) {
				buildDisplay = item.name;
			}
			if (item[idKey] == unit) {
				unitDisplay = item.name;
			}
			if (item[idKey] == floor) {
				floorDisplay = item.name;
			}
			if (item[idKey] == house) {
				houseDisplay = item.name;
			}
		});
		let value = '';
		if (buildDisplay) {
			value = buildDisplay;
			if (unitDisplay) {
				value = buildDisplay + '/' + unitDisplay;
				if (floorDisplay) {
					value = buildDisplay + '/' + unitDisplay + '/' + floorDisplay;
					if (houseDisplay) {
						value = buildDisplay + '/' + unitDisplay + '/' + floorDisplay + '/' + houseDisplay;
					}
				}
			}
		}
		this.setState({
			defaultValue: value,
			showSelect: false
		});
	};
	componentDidMount(): void {
		// this.handleDefaultValue(this.props.record);
		getAreaTreeByType({
			hasAll: false,
			levels: ['HOUSE']
		}).then((res: any) => {
			const listTree = getFlatTreeData(res);
			this.setState(
				{
					treeList: listTree
				},
				() => {
					if (Object.keys(this.props.record).length > 0) {
						this.handleDefaultValue();
					}
				}
			);
		});
	}

	buildTreeData = (treeData: any[], title: string, key: string, children: string, parentId: any) => {
		const result: { title: string; key: string; parentId: string; children: any[] }[] = [];
		treeData.map((item: any) => {
			const treeNodeData: any = {
				title: item[title],
				name: item[title],
				key: item[key],
				value: item[key],
				parentId: parentId,
				...item
			};
			if (Array.isArray(item[children])) {
				treeNodeData.children = this.buildTreeData(item[children], title, key, children, item[key]);
			}
			result.push(treeNodeData);
		});
		return result;
	};

	//* 根据区域获取楼宇
	getBuildingList = (buildAreaId: any, callback?: Function) => {
		const { treeList } = this.state;
		const buildList = treeList.filter((item) => {
			return item.parentId == buildAreaId && item.treeNodeType === 'BUILDING';
		});
		const buildResult = buildList.map((item) => {
			return {
				value: item[this.state.idKey],
				label: item.name,
				isLeaf: false,
				level: 'building',
				permission: item.permission
				// disabled: !item.permission
			};
		});
		this.setState(
			{
				selectOption: buildResult,
				defaultValue: '',
				selectValue: []
			},
			() => {
				callback && callback(buildResult);
			}
		);
		// const res: any = await BaseData.queryBuildInfos({
		// 	query: {
		// 		// pageNum: 1,
		// 		// pageSize: 1000,
		// 		areaId: buildAreaId
		// 	}
		// });
		// if (res.code === 0 && Array.isArray(res.data)) {
		// 	if (res.data.length > 0) {
		// 		const selectOption: any = res.data.map((item: any) => {
		// 			return {
		// 				value: item.id.toString(),
		// 				label: item.buildName,
		// 				isLeaf: false,
		// 				level: 'building'
		// 			};
		// 		});
		// 		this.setState(
		// 			{
		// 				selectOption,
		// 				defaultValue: '',
		// 				selectValue: []
		// 			},
		// 			() => {
		// 				callback && callback(selectOption);
		// 			}
		// 		);
		// 	} else {
		// 		this.setState(
		// 			{
		// 				selectOption: [],
		// 				selectValue: [],
		// 				defaultValue: ''
		// 			},
		// 			() => {
		// 				callback && callback([]);
		// 			}
		// 		);
		// 	}
		// }
	};

	onSelectFill = (areaId: any) => {
		const { selectValue = [] } = this.state;
		if (areaId) {
			this.getBuildingList(areaId, async (builds: any) => {
				let buildChildren: any = [];
				let unitChildren: any = [];
				let floorChildren: any = [];
				if (selectValue.length > 0) {
					buildChildren = this.getUnitBuilding({ value: selectValue[0] });

					if (selectValue.length > 1 && buildChildren.length > 0) {
						unitChildren = this.getFloorByUnit({ value: selectValue[1] });
					}
					if (selectValue.length > 2 && unitChildren.length > 0) {
						floorChildren = this.getHouseByFloor({ value: selectValue[2] });
					}
					const buildRecord = builds.find((b: any) => b.value === selectValue[0]);
					const unitRecord = buildChildren.find((u: any) => u.value === selectValue[1]);
					const floorRecord = unitChildren.find((f: any) => f.value === selectValue[2]);
					const houseRecord = floorChildren.find((h: any) => h.value === selectValue[3]);
					const buildName = buildRecord && buildRecord.label;
					const unitName = unitRecord && unitRecord.label;
					const floorName = floorRecord && floorRecord.label;
					const houseName = houseRecord && houseRecord.label;
					const defaultValue = [buildName, unitName, floorName, houseName].filter(Boolean).join('/');

					this.setState({
						defaultValue: buildName ? defaultValue : '',
						showSelect: false
					});
				}
			});
		} else {
			this.setState({
				defaultValue: '',
				showSelect: true
			});
		}
	};

	loadData = async (selectedOptions: any) => {
		const targetOption: any = selectedOptions[selectedOptions.length - 1];
		targetOption.loading = true;
		if (targetOption.level === 'building') {
			const children = this.getUnitBuilding(targetOption);
			targetOption.loading = false;
			targetOption.children = children;
		} else if (targetOption.level === 'unit') {
			targetOption.children = this.getFloorByUnit(targetOption);
			targetOption.loading = false;
		} else if (targetOption.level === 'floor') {
			const children = this.getHouseByFloor(targetOption);
			targetOption.loading = false;
			targetOption.children = children;
		}
		this.setState({
			selectOption: [...this.state.selectOption]
		});
	};

	getUnitBuilding = (targetOption: any) => {
		const { treeList } = this.state;
		const unitList = treeList.filter((item) => {
			return item.parentId == targetOption.value && item.treeNodeType === 'UNIT';
		});
		const unitResult = unitList.map((item) => {
			return {
				value: item[this.state.idKey],
				label: item.name,
				isLeaf: this.props.level === 'unit',
				level: 'unit',
				permission: item.permission
				// disabled: !item.permission
			};
		});
		this.setState({
			buildingId: targetOption.value
		});

		return unitResult;
		// const result: any = await BaseData.getElementList({
		// 	body: {
		// 		pageNum: 1,
		// 		pageSize: 1000,
		// 		unitAreaId: this.state.areaId,
		// 		unitBuildId: targetOption.value
		// 	}
		// });
		// if (result.code === 0 && Array.isArray(result.data.list)) {
		// 	const children: any = result.data.list.map((item: any) => {
		// 		const childObj = {
		// 			value: item.id.toString(),
		// 			label: item.unitName,
		// 			isLeaf: this.props.level === 'unit', //是否只能选择到 单元
		// 			level: 'unit'
		// 		};
		// 		return childObj;
		// 	});

		// 	return children;
		// } else {
		// 	return [];
		// }
	};
	getFloorByUnit = (targetOption: any) => {
		this.setState({
			unitId: targetOption.value
		});
		const { treeList } = this.state;
		const floorList = treeList.filter((item) => {
			return item.parentId == targetOption.value && item.treeNodeType === 'FLOOR';
		});
		const listResult = floorList.map((item) => {
			return {
				value: item[this.state.idKey],
				label: item.title,
				isLeaf: this.props.level === 'floor',
				level: 'floor',
				permission: item.permission
			};
		});
		return listResult;
		// value: item.id.toString(),
		// 			label: item.houseNo,
		// 			isLeaf: true,
		// 			level: 'house'
		// const result: any = await BaseData.queryElement({
		// 	path: [targetOption.value]
		// });
		// if (result.code === 0) {
		// 	const { unitFloors = '0', unitDownFloors = '0' } = result.data;
		// 	const dataSource: any[] = this.handleFloorsData(Number(unitFloors), Number(unitDownFloors));
		// 	return dataSource;
		// } else {
		// 	return [];
		// }
	};
	getHouseByFloor = (targetOption: any) => {
		// this.setState({
		// 	unitId: targetOption.value
		// });
		const { treeList } = this.state;
		const houseList = treeList.filter((item) => {
			if (this.props.idKey === 'id') {
				return item.parentId == targetOption.value && item.treeNodeType === 'HOUSE';
			} else {
				return item.parentId == `${this.state.unitId}-${targetOption.value}` && item.treeNodeType === 'HOUSE';
			}
		});
		const houseResult = houseList.map((item) => {
			return {
				value: item[this.state.idKey],
				label: item.title,
				isLeaf: true,
				level: 'house'
			};
		});
		return houseResult;
		// const result: any = await BaseData.getHouseByOther({
		// 	body: {
		// 		// pageNum: 1,
		// 		// pageSize: 10000,
		// 		houseRegion: this.state.areaId,
		// 		houseBuilding: this.state.buildingId,
		// 		houseUnit: this.state.unitId,
		// 		houseUnitFloor: Number(targetOption.value)
		// 	}
		// });
		// if (result.code === 0 && Array.isArray(result.data)) {
		// 	const children: any = result.data.map((item: any) => {
		// 		return {
		// 			value: item.id.toString(),
		// 			label: item.houseNo,
		// 			isLeaf: true,
		// 			level: 'house'
		// 		};
		// 	});
		// 	return children;
		// } else {
		// 	return [];
		// }
	};
	handleFloorsData = (up: number, down: number) => {
		const upObj = new Array(up).fill(0).map((item: any, idx: number) => {
			return {
				value: (idx + 1).toString(),
				label: `${idx + 1}层`,
				level: 'floor',
				isLeaf: this.props.level === 'floor' //是否只能选择到 楼层
			};
		});
		const downObj = new Array(down).fill(0).map((item: any, idx: number) => {
			return {
				value: (0 - (idx + 1)).toString(),
				label: `${0 - (idx + 1)}层`,
				level: 'floor',
				isLeaf: this.props.level === 'floor' //是否只能选择到 楼层
			};
		});
		const dataSource: any[] = [...downObj, ...upObj];
		return dataSource;
	};
	onSelectChange = (value: any, selectedOptions: any) => {
		this.setState(
			{
				selectValue: value
			},
			() => {
				this.timer = setTimeout(() => {
					this.props.onChange(value, selectedOptions);
				}, 500);
			}
		);
	};
	private timer: any = null;
	componentWillUnmount() {
		// this.setState({
		// 	// treeData: [],
		// 	// selectOption: [],
		// 	// selectValue: [],
		// 	// areaId: '',
		// 	// unitId: '',
		// 	// buildingId: '',
		// 	showSelect: true
		// 	// defaultValue: ''
		// });
		clearTimeout(this.timer);
	}
	CascaderRef: any;
	render() {
		// console.log(this.state.showSelect, this.state.selectValue, this.state.selectOption);
		return (
			<div className="address-select">
				{this.state.showSelect ? (
					<Cascader
						className="select"
						options={this.state.selectOption}
						placeholder="请选择"
						onChange={this.onSelectChange}
						changeOnSelect
						loadData={this.loadData}
						value={this.state.selectValue}
						disabled={this.props.disabled}
						ref={(ref) => (this.CascaderRef = ref)}
					/>
				) : (
					<Input
						value={this.state.defaultValue}
						placeholder="请选择"
						onClick={() => {
							this.setState(
								{
									showSelect: true
								},
								() => {
									this.props.clearInput && this.props.clearInput();
									this.onSelectFill(this.props.areaId);
									// this.CascaderRef?.focus();
								}
							);
						}}
						disabled={this.props.disabled}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AddressSelect);
