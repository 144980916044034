import React, { Component, Fragment } from 'react';
import './index.less';
import * as redux from 'react-redux';
import { Tree, Input } from 'antd';
import { BaseData, Device, Management, Permission } from '../../api';
import { getTreeNodeByKey, TreeItem, buildTreeData, getFlatTreeData, storage } from '../../utils';
import { Props, State } from './interface';
import AREA from '@/static/icon/area.png';
import BUILDING from '@/static/icon/building.png';
import PARK_DEV from '@/static/icon/monitor.png';
import UNIT from '@/static/icon/building.png';
import FLOOR from '@/static/icon/building.png';
import HOUSE from '@/static/icon/building.png';
import CARPARK from '@/static/icon/car-park.png';
import EXTINGUISHER from '@/static/icon/car-park.png';
import baseData from '@/api/baseData';

const { Search } = Input;
const { connect } = redux;
const treeIconMap: any = {
	AREA,
	BUILDING,
	PARK_DEV,
	UNIT,
	FLOOR,
	HOUSE,
	CARPARK,
	EXTINGUISHER
};

class AreaTree extends Component<Props, State> {
	expandedKey: any[];
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (Array.isArray(nextProps.treeData) && nextProps.treeData.length > 0) {
			const { treeNodeType = 'AREA', hasAll = false } = nextProps;
			const parent = [
				{
					agencyId: 'all',
					agencyName: '全部',
					children: nextProps.treeData
				}
			];
			const treeData = buildTreeData(
				hasAll ? parent : nextProps.treeData,
				'agencyName',
				'agencyId',
				'children',
				''
			);
			return {
				treeData: treeData
			};
		} else {
			return {};
		}
	}
	constructor(props: Props) {
		super(props);
		this.state = {
			treeData: [],
			selectedKeys: [],
			expandedKeys: [],
			showTree: false,
			searchVal: '',
			permissionTreeData: []
		};
		this.expandedKey = [];
	}

	componentDidMount(): void {
		this.props.onRef && this.props.onRef(this);
		this.init();
	}
	changeTreeSelected = (key: string) => {
		if (this.state.selectedKeys.length > 0) {
			this.setState({
				selectedKeys: [key]
			});
		}
	};
	getTreeSelected = () => {
		return this.state.selectedKeys;
	};
	setSelectAndExpandByProps = (selects: string[], expands: string[]) => {
		this.setState({
			selectedKeys: selects,
			expandedKeys: expands
		});
	};
	init = () => {
		if (this.props.treeData) {
			if (this.props.showFirstNode) {
				this.setState(
					{
						showTree: true
					},
					() => {
						this.setState({
							selectedKeys: [this.props.treeData[0]?.key],
							expandedKeys: [this.props.treeData[0]?.key]
						});
					}
				);
			} else {
				this.setState(
					{
						showTree: true
					},
					() => {
						this.setState({
							selectedKeys: ['all']
						});
					}
				);
			}
			return;
		}
		// 新增树节点时 useCache= false 不使用缓存， 默认使用缓存
		// 将树结构数据存储在redux里 每次先拿redux里面的数据 拿不到在请求接口
		let treeData: any[] = [];
		const { treeNodeType = 'AREA' } = this.props;
		if (treeNodeType === 'ORG') {
			treeData = this.props.orgTree || [];
		} else if (treeNodeType === 'AREA') {
			treeData = this.props.areaTree || [];
		} else if (treeNodeType === 'devGroup') {
			treeData = this.props.devTree || [];
		}
		const { useCache = false } = this.props;
		if (Array.isArray(treeData) && treeData.length > 0 && useCache) {
			this.setState({
				treeData: treeData,
				showTree: true
			});
			if (this.props.showFirstNode) {
				const defaultKey = treeData[0]?.key;
				this.onResetTree([defaultKey]);
				this.props.onSelect && this.props.onSelect(defaultKey, true, treeData[0]?.title, treeData[0]?.name);
			}
		} else {
			this.initTree();
		}
	};
	onInputClear = () => {
		// this.setState({
		// 	searchVal: ''
		// });
	};
	onExpand = (e: string[]) => {
		this.setState({
			expandedKeys: e
		});
	};
	initTreeSelect = (treeData: any) => {
		if (this.props.permission === false) {
			this.props.getTreeData && this.props.getTreeData(treeData);
		} else {
			this.props.getTreeData && this.props.getTreeData(this.state.permissionTreeData);
		}
		this.setState({
			treeData,
			showTree: true
		});
		if (this.props.showFirstNode) {
			const defaultKey: any = treeData[0]?.key;
			this.onResetTree([defaultKey]);
			this.props.onSelect && this.props.onSelect(defaultKey, true, treeData[0]?.title, treeData[0]?.name);
		}
	};
	initTree = () => {
		const { treeNodeType = 'AREA', hasAll = false, treeKey = 'id', role = '' } = this.props;
		if (treeNodeType === 'ORG') {
			BaseData.getAgencyTree({
				body: { needAuth: 1 }
			}).then((res: any) => {
				if (res.code === 0) {
					if (Array.isArray(res.data)) {
						const parent = [
							{
								agencyId: 'all',
								agencyName: '全部',
								children: res.data
							}
						];
						const treeData = buildTreeData(
							hasAll ? parent : res.data,
							'agencyName',
							'agencyId',
							'children',
							''
						);
						this.props.setOrgTree && this.props.setOrgTree(treeData);
						this.initTreeSelect(treeData);
					}
				}
			});
		} else if (treeNodeType === 'AREA') {
			// 根据treeNodeType来决定调用机构树还是区域树
			BaseData.treeArea({
				query: {
					treeNodeType //可用值:AREA,BUILDING,ORG,COMPANY
				}
			}).then((res: IotResponse) => {
				if (res.code === 0) {
					if (Array.isArray(res.data)) {
						const parent = [
							{
								id: 'all',
								name: '全部',
								children: res.data
							}
						];
						const treeData = this.buildTreeData(hasAll ? parent : res.data, 'name', 'id', 'children', '');
						const permissionTreeData = this.buildTreePermissionData(
							hasAll ? parent : res.data,
							'name',
							'id',
							'children',
							''
						);
						this.setState({
							permissionTreeData
						});
						this.props.setAreaTree && this.props.setAreaTree(treeData);
						this.initTreeSelect(treeData);
					}
				}
			});
		} else if (treeNodeType === 'LIGHT' || treeNodeType === 'RADIO' || treeNodeType === 'MUSIC') {
			// 根据treeNodeType来决定调用机构树还是区域树
			Device.getGroupList({
				query: {
					type: treeNodeType
				}
			}).then((res: IotResponse) => {
				if (res.code === 0) {
					if (Array.isArray(res.data)) {
						const parent = [
							{
								id: 'all',
								name: '全部',
								children: res.data
							}
						];
						const treeData = this.buildTreeData(hasAll ? parent : res.data, 'groupName', 'id', 'child', '');
						const permissionTreeData = this.buildTreePermissionData(
							hasAll ? parent : res.data,
							'groupName',
							'id',
							'child',
							''
						);
						this.setState({
							permissionTreeData
						});
						this.props.setAreaTree && this.props.setAreaTree(treeData);
						this.initTreeSelect(treeData);
					}
				}
			});
		} else if (treeNodeType === 'FIRM') {
			BaseData.getEnterprisePermission().then((res: IotResponse) => {
				if (res.code === 0) {
					if (Array.isArray(res.data)) {
						const parent = [
							{
								id: 'all',
								name: '全部',
								children: res.data
							}
						];
						const treeData = this.buildTreeData(
							hasAll ? parent : res.data,
							'enterpriseName',
							'id',
							'child',
							''
						);
						const permissionTreeData = this.buildTreePermissionData(
							hasAll ? parent : res.data,
							'enterpriseName',
							'id',
							'child',
							''
						);
						this.setState({
							permissionTreeData
						});
						this.props.setAreaTree && this.props.setAreaTree(treeData);
						this.initTreeSelect(treeData);
					}
				}
			});
		} else if (treeNodeType === 'COMPANYLIST') {
			BaseData.getEnterpriseList({
				body: {
					pageNum: 1,
					pageSize: 10000
				}
			}).then((res: any) => {
				if (res.code === 0) {
					const { list = [] } = res.data;

					const treeData = list.map((item: any) => {
						return {
							...item,
							key: item.id,
							value: item.id,
							title: item.enterpriseName,
							name: item.enterpriseName,
							parentId: hasAll ? 'all' : ''
						};
					});
					const parent = [
						{
							id: 'all',
							name: '全部',
							children: treeData
						}
					];
					const treeDataResult = this.buildTreeData(
						hasAll ? parent : treeData,
						'enterpriseName',
						'id',
						'children',
						''
					);
					if (hasAll) {
						this.initTreeSelect(treeDataResult);
					} else {
						this.initTreeSelect(treeData);
					}
				}
			});
		} else if (treeNodeType === 'CARPARK') {
			Management.getCarAreaParkTree()
				.then((res: any) => {
					if (res.code === 0) {
						const parkTree = res.data || [];
						const parkData = JSON.parse(JSON.stringify(parkTree));
						const parkTreeData = buildTreeData(parkData, 'parkAreaName', 'parkAreaId', 'child', '');
						this.initTreeSelect(parkTreeData);
					}
				})
				.catch((error: any) => {});
		} else if (treeNodeType === 'UserGroup') {
			Permission.getUserGroupByRole({
				query: {
					userId: storage.getSession('appId'),
					role: role
				}
			}).then((res: any) => {
				if (res.code === 0) {
					const { data = [] } = res;
					const treeData = data.map((item: any) => {
						return {
							...item,
							key: item.id,
							value: item.id,
							title: item.name,
							name: item.name,
							parentId: hasAll ? 'all' : ''
						};
					});
					const parent = [
						{
							id: 'all',
							name: '全部',
							children: treeData
						}
					];
					const treeDataResult = this.buildTreeData(hasAll ? parent : treeData, 'name', 'id', 'children', '');
					if (hasAll) {
						this.initTreeSelect(treeDataResult);
					} else {
						this.initTreeSelect(treeData);
					}
				}
			});
		} else if (treeNodeType === 'BFCOMPANYLIST') {
			BaseData.getBfenterprise({
				body: {
					pageSize: 1000,
					pageNum: 1
				}
			}).then((res: any) => {
				if (res.code === 0) {
					const {
						data: { list = [] }
					} = res;
					const treeData = list.map((item: any) => {
						return {
							...item,
							key: item.id,
							value: item.id,
							title: item.enterpriseName,
							name: item.enterpriseName,
							parentId: hasAll ? 'all' : ''
						};
					});
					const parent = [
						{
							id: 'all',
							name: '全部',
							children: treeData
						}
					];
					const treeDataResult = this.buildTreeData(
						hasAll ? parent : treeData,
						'enterpriseName',
						'id',
						'children',
						''
					);
					if (hasAll) {
						this.initTreeSelect(treeDataResult);
					} else {
						this.initTreeSelect(treeData);
					}
				}
			});
		} else {
			BaseData.getAreaTreeByType({
				body: treeNodeType
				// {
				// 	data: treeNodeType //可用值:AREA,BUILDING,ORG,COMPANY
				// }
			}).then((res: IotResponse) => {
				if (res.code === 0) {
					if (Array.isArray(res.data)) {
						const parent: any = [
							{
								id: 'all',
								key: 'all',
								name: '全部',
								children: res.data
							}
						];

						const treeData = this.buildTreeData(
							hasAll ? parent : res.data,
							'name',
							treeKey,
							'children',
							''
						);
						const permissionTreeData = this.buildTreePermissionData(
							hasAll ? parent : res.data,
							'name',
							treeKey,
							'children',
							''
						);
						this.setState({
							permissionTreeData
						});
						this.props.setAreaTree && this.props.setAreaTree(treeData);
						this.initTreeSelect(treeData);
					}
				}
			});
		}
	};
	buildTreePermissionData = (treeData: any[], title: string, key: string, children: string, parentId: any) => {
		const result: { title: string; key: string; parentId: string; children: TreeItem[] }[] = [];
		treeData.map((item: any) => {
			// console.log(item);
			const treeNodeData: any = {
				title: this.renderTreeTitle(item, key, title),
				name: item[title],
				key: item[key],
				value: item[key],
				parentId: parentId,
				selectable: item.permission,
				...item
			};
			if (Array.isArray(item[children])) {
				treeNodeData.children = this.buildTreePermissionData(item[children], title, key, children, item[key]);
			}
			result.push(treeNodeData);
		});
		return result;
	};
	buildTreeData = (treeData: any[], title: string, key: string, children: string, parentId: any) => {
		const result: { title: string; key: string; parentId: string; children: TreeItem[] }[] = [];
		treeData.map((item: any) => {
			// console.log(item);
			const treeNodeData: any = {
				title: this.renderTreeTitle(item, key, title),
				name: item[title],
				key: item[key],
				value: item[key],
				parentId: parentId,
				...item
			};

			if (Array.isArray(item[children])) {
				treeNodeData.children = this.buildTreeData(item[children], title, key, children, item[key]);
			}
			result.push(treeNodeData);
		});
		return result;
	};
	renderTreeTitle = (item: any, key: string, title: string) => {
		return item[title];
	};
	// 根据节点key选中节点及展开父节点
	onResetTree = (id: string[]) => {
		this.allExpand = [];
		this.setState({
			selectedKeys: id,
			expandedKeys: this.getParentId(id[0])
		});
	};
	onSelectTreeNode = (e: string[], flash: boolean, node?: any, name?: string, item?: any) => {
		const { onSelect, clearable = true } = this.props;
		if (e.length > 0) {
			this.setState({
				selectedKeys: e
			});
			// 选择节点后是否清空输入框
			if (clearable) {
				this.onInputClear();
			}
			// 如果是选择全部，则选择节点id为空
			if (e[0] === 'all') {
				onSelect && onSelect('', flash, node, name, item);
				return;
			}
			onSelect && onSelect(e[0], flash, node, name, item);
		}
	};
	private allExpand: string[] = [];
	// 根据当前筛选到的Id 遍历展开所有指定的父级树节点
	getParentId = (id: string) => {
		const treeData = this.state.treeData;
		const result = getTreeNodeByKey(treeData, id, false);
		if (result) {
			this.allExpand.push(result.key);
			//@ts-ignore
			this.getParentId(result.parentId);
		}
		return this.allExpand;
	};

	getParentKey = (key: any, tree: any): any => {
		let parentKey;
		for (let i = 0; i < tree.length; i++) {
			const node = tree[i];
			if (node.children) {
				if (node.children.some((item: any) => item.key === key)) {
					parentKey = node.key;
				} else if (this.getParentKey(key, node.children)) {
					parentKey = this.getParentKey(key, node.children);
				}
			}
		}

		return parentKey;
	};

	onSearch = (e: string) => {
		const { treeData } = this.state;
		this.setState({
			searchVal: e
		});
		this.allExpand = [];
		const result: AnyObject = getFlatTreeData(this.state.treeData);
		const deepParentKeys = (key: any) => {
			const arr: any = [];
			const deepParentKeys = (key1: any) => {
				if (key1) {
					arr?.push(key1);
					deepParentKeys(this.getParentKey(key1, treeData));
				}
			};
			deepParentKeys(key);
			return arr;
		};
		if (e) {
			if (result?.length) {
				// const expandedKeys = this.getParentId(result.key);
				let arr: any = [];
				const expandedKeys = result
					.map((item: any) => {
						if (item?.name?.indexOf(e) > -1) {
							arr.push(item.key);
							arr = arr.concat(deepParentKeys(this.getParentKey(item.key, treeData)));
							return arr;
						}
						return null;
					})
					.filter((item: any, i: any, self: any) => item && self.indexOf(item) === i);
				this.expandedKey = expandedKeys.concat(arr);
				this.setState(
					{
						// selectedKeys: [result.key],
						expandedKeys: expandedKeys.concat(arr)
					},
					() => {
						// console.log(result, 'result');
						// @ts-ignore
						// this.props.onSearch && this.props.onSearch(result.key, result, result.title, result.name);
					}
				);

				// title: <div>title.split('e')[0]<span style="gaoliang">{e}</span> </div>
				// this.setState({

				// })
			} else {
				let value: string = '';
				if (this.props.treeData && this.props.treeData[0] && this.props.treeData[0].key === 'all') {
					value = 'all';
				}
				this.onClearTree(value, () => {
					// @ts-ignore
					this.props.onSearch &&
						this.props.onSearch('', {
							noResult: true
						});
				});
			}
		} else {
			this.setState({
				selectedKeys: []
			});

			let value: string = '';
			if (this.props.treeData && this.props.treeData[0] && this.props.treeData[0].key === 'all') {
				value = 'all';
			}
			this.onClearTree(value, () => {
				// @ts-ignore
				this.props.onSearch &&
					this.props.onSearch('', {
						noParam: true
					});
			});
		}
	};
	onClearTree = (value: string, cb?: Function) => {
		this.setState(
			{
				selectedKeys: [value],
				expandedKeys: [],
				searchVal: ''
			},
			() => {
				cb && cb();
			}
		);
	};

	deleteNoTitleData = (data: any) => {
		const data1: any = JSON.parse(JSON.stringify(data));
		const { searchVal } = this.state;
		let arr1: any = [];
		const obj: any = {};
		if (searchVal && searchVal !== '') {
			const loopdata = (data3: any, obj1: any) => {
				obj1.children = [];
				data3?.map((item: any, index: number) => {
					if (this.expandedKey?.indexOf(item.key) > -1) {
						obj1.children.push(item);

						item?.children && item?.children?.length && loopdata(item.children, item);
					}
				});
			};
			loopdata(data1, obj);
			arr1 = obj.children;
		} else {
			arr1 = data1;
		}

		return arr1;
	};

	loop = (data: any) => {
		const { searchVal } = this.state;
		const data1: any = this.deleteNoTitleData(data);
		const loop1 = (data2: any) => {
			return data2?.map((item: any) => {
				const index = item?.name?.indexOf(searchVal);
				const beforeStr = item?.name?.substr(0, index);
				const afterStr = item?.name?.substr(index + searchVal.length);

				const title =
					index > -1 ? (
						item.importArea ? (
							<div className={this.props?.titleClass} key={item.key}>
								<span style={{ marginRight: 10 }}>
									<span>{beforeStr}</span>
									<span className="site-tree-search-value">{searchVal}</span>
									<span>{afterStr}</span>
								</span>

								{this.props?.renderEditor && this.props?.renderEditor(item, this.props?._this)}

								<i className="iconfont spguiji"></i>
							</div>
						) : (
							<div className={this.props?.titleClass} key={item.key}>
								<span>
									<span>{beforeStr}</span>
									<span className="site-tree-search-value">{searchVal}</span>
									<span>{afterStr}</span>
								</span>

								{this.props?.renderEditor && this.props?.renderEditor(item, this.props?._this)}
							</div>
						)
					) : item.importArea ? (
						<div className={this.props?.titleClass} key={item.key}>
							<span style={{ marginRight: 10 }}>
								<span>{item.name}</span>
							</span>
							{this.props?.renderEditor && this.props?.renderEditor(item, this.props?._this)}
							<i className="iconfont spguiji"></i>
						</div>
					) : (
						<div className={this.props?.titleClass} key={item.key}>
							<span>{item.name}</span>
							{this.props?.renderEditor && this.props?.renderEditor(item, this.props?._this)}
						</div>
					);

				if (item.children) {
					if (this.props.showIcon && item.treeNodeType && !item.icon) {
						item.icon = <img src={treeIconMap[item.treeNodeType]} />;
					}
					return {
						...item,
						name: item?.name,
						title: title,
						key: item.key,
						children: loop1(item.children)
					};
				}
				if (this.props.showIcon && item.treeNodeType && !item.icon) {
					item.icon = <img src={treeIconMap[item.treeNodeType]} />;
				}
				return {
					...item,
					name: item?.name,
					title,
					key: item.key
				};
			});
		};

		return loop1(data1);
	};
	render() {
		//@ts-ignore
		const { width = '250px', height = '100%' } = this.props;
		return (
			<Fragment>
				<div className="dev-tree" style={{ width, height }}>
					<div className="dev-tree-search">
						<Search
							disabled={this.props?.searchDisabled || false}
							maxLength={this.props.maxLength || 30}
							placeholder="请输入搜索内容"
							// @ts-ignore
							onSearch={(e: string) => {
								this.onSearch(e);
							}}
							onChange={(e) =>
								// this.setState({ searchVal: e.target.value }, () => {
								// 	this.props.searchValOnChange && this.props.searchValOnChange(e.target.value);
								// })
								{
									// this.props.searchValOnChange && this.props.searchValOnChange(e.target.value);
								}
							}
						/>
					</div>
					{this.state.showTree ? (
						<Tree
							style={{ width: '100%' }}
							// autoExpandParent={true}
							expandedKeys={this.state.expandedKeys}
							//@ts-ignore
							onSelect={(e: any, detail: any) => {
								this.onSelectTreeNode(
									e,
									true,
									detail.node.title,
									detail.node.name,
									detail.selectedNodes[0]
								);
							}}
							showIcon={true}
							//@ts-ignore
							onExpand={this.onExpand}
							selectedKeys={this.state.selectedKeys}
							treeData={this.loop(this.state.treeData)}
							onCheck={(e1: any, e2: any, info: any) => {
								console.log(info);
								this.props?.onCheck && this.props?.onCheck(e1, e2, info);
							}}
							checkable={this.props?.checkable || false}
							{...this.props.treeProps}
						/>
					) : null}
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: any) => {
	return {
		setAreaTree: (data: any) => dispatch({ type: 'SET_AREA_TREE', data }),
		setOrgTree: (data: any) => dispatch({ type: 'SET_ORG_TREE', data })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AreaTree);
