import React, { Fragment, useEffect, useState, useRef } from 'react';
import './index.less';
import { storage } from '../../utils';
import _fetch from '../../api/request';
import { message, Button, Upload, Image, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Props } from './interface';
import defImage from '@/static/images/defimage.png';
import IotPhoto from '../IotPhoto';

const ImageUpload: React.FC<Props> = (props: Props) => {
	const [imageUrl, setImageUrl] = useState<any>('');
	const [fileList, setFileList] = useState<any[]>([]);
	const [countLimitDisable, setCountLimitDisable] = useState<boolean>(false);
	const [once, setOnce] = useState<boolean>(false);
	const fileCount = useRef<number>(0);
	const mediaCount = useRef<number>(0);
	const domainName = window.location.origin;
	// @ts-ignore
	const appId = storage.getSession('appId');

	// @ts-ignore
	const companyId = props._cid || storage.getSession('login').companyId;
	const prevUrl = `${domainName}/smartparksysgeneral/v1.0.0/file/download?appId=${appId}&companyId=${companyId}&filename=`;
	const initFileList = (names: string) => {
		const { multiple, delimiter, _cid } = props;

		if (multiple === true) {
			if (!names) {
				setFileList([]);
				setImageUrl('');
			}
			if (delimiter) {
				const arr = names?.split(delimiter);
				if (Array.isArray(arr) && arr[0]) {
					const fileListResult: any[] = [];
					arr.map((item, index) => {
						const fileObj = {
							name: item,
							status: 'success',
							uid: '_' + index + '_' + new Date().getTime(),
							url: prevUrl + encodeURIComponent(item)
						};
						fileListResult.push(fileObj);
					});
					setFileList(fileListResult);
					const { maxCount = 0 } = props;
					const disable = !!maxCount && maxCount <= fileListResult.length;
					setCountLimitDisable(disable);
				}
			} else {
				//
			}
		} else {
			if (names?.length && names[0]) {
				if (names?.indexOf('http') > -1 || names?.indexOf('https') > -1) {
					setImageUrl(names);
				} else {
					setImageUrl(encodeURIComponent(names));
				}
			} else {
				setImageUrl('');
			}
		}
	};
	useEffect(() => {
		if (imageUrl && imageUrl?.length) {
			imageUrl?.length && props?.onChange && props?.onChange(imageUrl);
			setOnce(true);
		}
	}, [imageUrl]);
	useEffect(() => {
		initFileList(props?.defaultImgUrl);
	}, [props?.defaultImgUrl, domainName, companyId, appId]);

	const assignFileList = (file: AnyObject, option: AnyObject) => {
		const fileResult = Object.assign({}, file, option);
		fileList.push(fileResult);
		setFileList(fileList);
		const { maxCount = 0 } = props;
		const disable = !!maxCount && maxCount <= fileList.length;
		setCountLimitDisable(disable);
	};

	const uploadProps = () => {
		const {
			uploadType = 'file',
			multiple = false,
			maxCount = 0,
			listType,
			accept = 'image/png, image/jpg, image/jpeg, image/gif, image/bmp'
		} = props;

		return {
			name: 'file',
			accept: uploadType === 'file' ? null : accept,
			multiple,
			maxCount,
			listType,
			showUploadList: !!listType,
			headers: {
				appId: storage.getSession('appId'),
				appKey: storage.getSession('pwd'),
				'iot-token': storage.getSession('token')
			},
			beforeUpload: (file: any) => {
				const pattern = new RegExp('[#?&]');
				if (pattern.test(file.name)) {
					message.warning('文件名中含有非法字符');
					return false;
				}
				if (uploadType === 'file') {
					if (maxCount > 0 && fileCount.current >= maxCount) {
						return false;
					} else {
						fileCount.current++;
					}
					uploadFile(file);
					return false;
				}
				if (uploadType === 'media') {
					if (maxCount > 0 && mediaCount.current >= maxCount) {
						return false;
					} else {
						mediaCount.current++;
					}
					uploadMedia(file);
					return false;
				}
				if (uploadType === 'base64') {
					uploadBase64(file);
					return false;
				}
			},
			onChange: (ev: any) => {
				// console.log(ev);
			},
			onRemove: (ev: any) => {
				const index = fileList.findIndex((item) => item.name === ev.name);
				fileList.splice(index, 1);
				setFileList([...fileList]);
				props.onChange && props.onChange(fileList);
				const { maxCount = 0 } = props;
				onFileCountRemove();
				const disable = !!maxCount && maxCount <= fileList.length;
				setCountLimitDisable(disable);
			}
		};
	};

	const uploadFile = (file: any) => {
		const { limitOption = [], limitSize = 5 } = props;
		const len = file.size / 1024 / 1024;
		if (limitOption && limitOption.length > 0) {
			for (let i = 0; i < limitOption.length; i++) {
				const item = limitOption[i];
				const nameArr = file.name.split('.');
				if (item.type.includes(`.${nameArr[nameArr.length - 1]}`)) {
					if (len > item.limit) {
						message.warning(`${item.text}文件大小不能超过${item.limit}M`);
						return false;
					}
				}
			}
		}
		//else {
		// 					message.warning(`${item.text}格式不符合要求，必须为${item.type.join('/')}格式`);
		// 					return false;
		// 				}
		if (len >= limitSize) {
			message.warning(`图片大小不能超过${limitSize}M`);
			return false;
		}
		uploadRequest(file);
	};

	const onFileCountRemove = () => {
		const { maxCount = 0, uploadType = 'file' } = props;
		if (maxCount > 0) {
			if (uploadType === 'file') {
				fileCount.current--;
			} else {
				mediaCount.current--;
			}
		}
	};

	const uploadMedia = (file: any) => {
		const { limitSize = 5 } = props;
		const len = file.size / 1024 / 1024;
		if (file.type.indexOf('video') > -1 || file.type.indexOf('avi') > -1) {
			if (len >= limitSize) {
				message.warning(`视频大小不能超过${limitSize || 50}M`);
				return false;
			}
		} else {
			if (len >= 10) {
				message.warning(`图片大小不能超过10M`);
				return false;
			}
		}
		uploadRequest(file);
	};

	const uploadBase64 = (file: any) => {
		const { limitSize = 5 } = props;
		const len = file.size / 1024 / 1024;
		if (limitSize && len >= limitSize) {
			message.info(`图片大小不能超过${limitSize}M`);
			return false;
		}
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			() => {
				if (reader.result) {
					setImageUrl(reader.result);

					props.getUploadImage && props.getUploadImage(reader.result);
				}
			},
			false
		);
		reader.readAsDataURL(file);
	};

	const uploadRequest = (file: any) => {
		const formData = new FormData();
		formData.append('file', file, `${new Date().getTime()}_${file.name}`);
		formData.append('companyId', companyId);
		_fetch({
			url: '/smartparksysgeneral/v1.0.0/file/upload',
			method: 'POST',
			body: formData,
			// @ts-ignore
			userId: storage.getSession('userId'),
			isFormData: true
		})
			.then((res: any) => {
				if (res.code === 0) {
					message.success(`${file.name}上传成功`);
					const fileName = res.data;
					const imageUrl = `${domainName}/smartparksysgeneral/v1.0.0/file/download?appId=${appId}&companyId=${companyId}&filename=${fileName}`;
					setImageUrl(fileName);
					assignFileList(file, { status: 'success', url: imageUrl, name: fileName });
					props.getUploadImage && props.getUploadImage(fileName, imageUrl, fileName, fileList);
				} else {
					onFileCountRemove();
				}
			})
			.catch((err: any) => {
				message.warning(err.msg);
				onFileCountRemove();
			});
	};

	const onImageRemove = () => {
		if (disabled || countLimitDisable) {
			return;
		}
		setImageUrl('');
		props.getUploadImage && props.getUploadImage('');
	};
	const {
		uploadType = 'file',
		isCustomRender = false,
		disabled = false,
		maxCount = 0,
		photoView = false,
		isPhotograph
	} = props;
	return (
		<Fragment>
			<div className="image-upload-wrapper">
				{(uploadType === 'file' || uploadType === 'media') && (
					<>
						{isCustomRender ? null : (
							<div className="form-images">
								{imageUrl ? (
									<>
										{disabled || countLimitDisable ? null : (
											<div className="image-del-icon" onClick={onImageRemove}>
												<i className="iconfont splajitong" />
											</div>
										)}
										{(photoView && disabled) || countLimitDisable ? (
											<Image
												src={prevUrl + imageUrl}
												alt=""
												width={'100%'}
												height={'100%'}
												preview={true}
												onError={() => {
													setImageUrl('');
												}}
											/>
										) : (
											<img
												src={prevUrl + imageUrl}
												alt=""
												onError={() => {
													setImageUrl('');
												}}
											/>
										)}
									</>
								) : (
									<span className="image-empty">
										<img src={defImage} alt="" />
									</span>
								)}
							</div>
						)}
						<div className="btn-pho-img">
							{isPhotograph && !props?.disabled ? (
								<IotPhoto
									disabled={props?.disabled}
									onChange={(value: any) => {
										setImageUrl([value]);
										// props?.onChange && props?.onChange(value);
									}}
								/>
							) : null}
							{
								//@ts-ignore
								<Upload {...uploadProps()} fileList={fileList}>
									{disabled || countLimitDisable ? null : props.children ? (
										props.children
									) : (
										<Button type="primary" disabled={disabled || countLimitDisable}>
											<UploadOutlined /> 上传
										</Button>
									)}
								</Upload>
							}
						</div>
					</>
				)}
				{uploadType === 'base64' && (
					<div className="form-images-base64">
						{
							//@ts-ignore
							<Upload {...uploadProps()} listType="picture-card" className="avatar-uploader">
								{imageUrl ? (
									<img src={prevUrl + imageUrl} alt="avatar" style={{ width: '100%' }} />
								) : (
									<div>
										<div style={{ marginTop: 2, fontSize: 12 }}>上传图片</div>
									</div>
								)}
							</Upload>
						}
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default ImageUpload;
