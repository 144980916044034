import React, { Fragment, useEffect, useState, useRef } from 'react';
import { message, Button, Upload, Image, Modal } from 'antd';
import { storage } from '../../utils';
import _fetch from '../../api/request';
import { UploadOutlined } from '@ant-design/icons';
import { Props } from './interface';
import phone from '@/static/icon/phone.png';
let mediaStreamTrack: any = null;
// @ts-ignore
const appId = storage.getSession('appId');
// @ts-ignore
const companyId = storage.getSession('login').companyId;
const domainName = window.location.origin;
const IotPhoto = (props: Props) => {
	const [isModalVisible, setIsModalVisible] = useState<boolean | undefined>();
	const [imgUrl, setImgUrl] = useState<string | undefined>();
	const [baseImg, setBaseImg] = useState<string | undefined>();
	const [file, setFile] = useState<any>();

	useEffect(() => {
		// 打开弹窗连接摄像头
		if (isModalVisible) {
			// openMedia();
		} else {
			if (isModalVisible === false) {
				closeMedia();
			}
		}
		// 关闭弹窗关闭摄像头
	}, [isModalVisible]);

	const openMedia = () => {
		message.error('暂不支持');
		return;
		let videoNum = 0;
		let microphoneNum = 0;
		const deviceList: any = [];

		navigator.mediaDevices
			?.enumerateDevices()
			?.then((devices: any) => {
				devices.forEach((device: any) => {
					//console.log(device);
					deviceList.push(device.kind);

					if (device.kind === 'videoinput') videoNum++;
					if (device.kind === 'audioinput') microphoneNum++;
				});
				if (videoNum === 0) {
					message.error('未检测到摄像头');
					return;
				}
				setIsModalVisible(true);
				const constraints = {
					video: { width: 500, height: 500 },
					audio: false
				};
				//获得video摄像头
				const video: any = document.getElementById('video');
				const promise = navigator?.mediaDevices?.getUserMedia(constraints);
				promise?.then((mediaStream) => {
					mediaStreamTrack = mediaStream.getVideoTracks();
					video.srcObject = mediaStream;
					video.play();
				});
			})
			.catch(function (err) {
				message.error(err.name + ': ' + err.message);
			});
	};

	const closeMedia = () => {
		const video: any = document?.getElementById('video');
		const stream: any = video?.srcObject;
		const tracks = stream?.getTracks();
		if (stream && tracks) {
			tracks?.forEach(function (track: any) {
				track.stop();
			});

			stream.srcObject = null;
		}
	};
	// 拍照
	const takePhoto = () => {
		const video = document.getElementById('video');
		const canvas: any = document.getElementById('canvas');
		const ctx = canvas.getContext('2d');
		ctx.drawImage(video, 0, 0, 300, 300);
		const img: any = canvas?.toDataURL();
		setBaseImg(img);
		const file: any = dataURLtoBlob(img);
		setFile(file);
	};

	const uploadRequest = (file: any) => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('companyId', companyId);
		_fetch({
			url: '/smartparksysgeneral/v1.0.0/file/upload',
			method: 'POST',
			body: formData,
			// @ts-ignore
			userId: storage.getSession('userId'),
			isFormData: true
		})
			.then((res: any) => {
				if (res.code === 0) {
					message.success(`${file.name}上传成功`);
					const fileName = res.data;
					const imageUrl = `${domainName}/smartparksysgeneral/v1.0.0/file/download?appId=${appId}&companyId=${companyId}&filename=${fileName}`;

					props?.onChange && props?.onChange(imageUrl);
					setImgUrl(imageUrl);
					close();
				} else {
					// onFileCountRemove();
				}
			})
			.catch((err: any) => {
				message.warning(err.msg);
				// onFileCountRemove();
			});
	};

	const dataURLtoBlob = (dataurl: string) => {
		const arr: any = dataurl.split(',');
		const mime: any = arr[0].match(/:(.*?);/)[1];
		const bstr: any = atob(arr[1]);
		let n: any = bstr.length;
		const u8arr: any = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return blobToFile(new Blob([u8arr], { type: mime }), '访客');
	};
	//将blob转换为file
	const blobToFile = (theBlob: any, fileName: any) => {
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;
		return theBlob;
	};

	const handleOk = () => {
		setIsModalVisible(false);
		if (file) {
			uploadRequest(file);
		} else {
			message.warn('请先拍照');
			return false;
		}
	};

	const handleCancel = () => {
		close();
	};

	const close = () => {
		closeMedia();
		setFile(undefined);
		setBaseImg(undefined);
		// setImgUrl(undefined);
		setIsModalVisible(false);
	};
	return (
		<>
			<Button
				type="primary"
				disabled={props?.disabled}
				onClick={() => {
					openMedia();
				}}
				style={{
					display: 'block',
					marginBottom: 35
				}}
			>
				<img src={phone} alt="" style={{ marginRight: 8, height: 14 }} /> 拍照
			</Button>
			<Modal
				title="拍照"
				visible={isModalVisible}
				onOk={() => {
					handleOk();
				}}
				onCancel={handleCancel}
				width={700}
			>
				<div>
					<video id="video" width="300px" height="300px"></video>
					<canvas id="canvas" width="300px" height="300px" style={{ display: 'none' }}></canvas>
					{baseImg ? <img id="imgTag" src={baseImg} alt="" style={{ verticalAlign: 'top' }} /> : null}
					{baseImg ? (
						<Button
							onClick={() => {
								takePhoto();
							}}
						>
							重新拍照
						</Button>
					) : (
						<Button
							onClick={() => {
								takePhoto();
							}}
						>
							拍照
						</Button>
					)}
				</div>
			</Modal>
		</>
	);
};

export default IotPhoto;
