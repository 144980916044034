const Org = {
	getOrgList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/agency/page'
	},
	getOrgLists: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/agency/list'
	},
	addOrg: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/agency/save'
	},
	updOrg: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/agency/update'
	},
	deleteOrg: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/agency/'
	},
	editOrgPerson: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/agency/assign/'
	},
	getAgencyTree: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/agency/tree'
	}
};

export default Org;
