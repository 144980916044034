const groupManage = {
	groupManagementSave: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/save'
	},
	groupManagementUpdate: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/update'
	},
	queryGroupInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/queryGroupInfo/'
	},
	groupManagementInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/info/'
	},
	groupManagementList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/list'
	},
	groupManagementDelete: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/delete'
	},
	memberManagementSave: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmember/save'
	},
	memberManagementUpdate: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmember/update'
	},
	memberManagementList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmember/list'
	},
	memberManagementInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/groupmember/info/'
	},
	memberManagementDelete: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupmember/delete'
	},
	groupScheduleDelete: {
		method: 'POST',
		url: 'smartparkbasedata/v1.0.0/groupschedule/delete'
	},
	groupScheduleInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/groupschedule/info/'
	},
	groupScheduleList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupschedule/list'
	},
	groupScheduleSave: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupschedule/save'
	},
	groupScheduleUpdate: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/groupschedule/update'
	},
	// 已存在区域
	queryExistArea: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/queryExistArea'
	},
	// 所有已被选id
	queryAllPersonId: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/queryAllPersonId'
	},
	getCurrentGroupInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/groupmanagement/queryGroupTeamSchedueInfo'
	},
	queryPeopleByIds: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/parkmanager/queryParkManagerByIds'
	},
	getWorkNumByUserId: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/parkmanager/getWorkNumByUserId'
	}
};

export default groupManage;
