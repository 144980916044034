const feedback = {
	getFeedBackList: {
		method: 'POST',
		url: '/smartpark-backand/v1.0.0/feedback/FeedBackList'
	},
	getFeedBackDetail: {
		method: 'GET',
		url: '/smartpark-backand/v1.0.0/feedback/FeedBackDetail'
	},
	recordFeedBack: {
		method: 'GET',
		url: '/smartpark-backand/v1.0.0/feedback/recordFeedBack'
	}
};
export default feedback;
