import React, { Component } from 'react';
import { storage, getSocketConf } from '../../utils';
import { Props, State } from './interface';
import SOCKET from '../../socket';
import * as redux from 'react-redux';
import './index.less';
import { notification, Button } from 'antd';
import MessageDrawer from '../MessageDrawer';
const messageSound = require('@/static/images/audio/companyReport.wav').default;

const { connect } = redux;

class MessageSocket extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			unReadCount: 0,
			total: 0,
			current: {}
		};
	}

	componentDidMount() {
		if (storage.getSession('appId')) {
			this.startSocket();
			this.getMessageList();
		}
	}

	private socket: AnyObject = {};
	startSocket = () => {
		const appId = storage.getSession('appId');
		if (appId) {
			const host = window.location.host || 'ws://192.168.101.66:9001';
			// const host = '192.168.101.66:30090';
			const protocol = window.location.protocol || 'http:';
			this.socket = new SOCKET({
				url: getSocketConf(host, protocol).url,
				onMessage: this.onSocketMessage
			});
			this.socket.start();
		}
	};

	onSocketMessage = (message: any) => {
		const messageObj: any = JSON.parse(message);
		const data = JSON.parse(messageObj.data);
		const msg = JSON.parse(data.message);
		const { type = '' } = data;
		const { content = '' } = msg;
		if (type === 'MESSAGE_INFO') {
			this.openNotification(content, msg);
			if (this.audioRef) {
				setTimeout(() => {
					this.audioRef?.load && this.audioRef?.load();
					setTimeout(() => {
						this.audioRef?.play && this.audioRef?.play();
					}, 1500);
				}, 600);
			}
			this.setState({
				current: msg
			});
		}
	};

	private key: string = 'updatable';
	// 产品需求： 每次只展示一次推送的消息内容 所以只更换消息体
	openNotification = (tips: string, record: any) => {
		notification.open({
			message: '',
			key: this.key,
			description: (
				<div className="notification-container">
					<span className="notification-title">
						<span className="notification-img"></span>
						<span>消息/公告提示</span>
					</span>
					<span className="notification-content">{tips}</span>
					<Button
						type="primary"
						onClick={() => {
							this.messageRef.handleMessage(this.state.current);
							notification.close(this.key);
						}}
					>
						查看消息
					</Button>
				</div>
			),
			className: 'notification-style',
			placement: 'bottomRight',
			duration: 300
		});
	};

	openMessage = () => {
		this.messageRef.open(() => {
			this.messageRef.init();
		});
	};
	getMessageList = () => {
		this.messageRef.fetchData((res: any) => {
			this.setState({
				// current: res.data.list[0] || res.data[0],
				unReadCount: res.data.unReadCount,
				total: res.data.total
			});
		});
	};

	getDrawerData = (total: any, unReadCount: any) => {
		this.setState({
			unReadCount: unReadCount,
			total: total
		});
	};

	private messageRef: any;
	private audioRef: any;
	render() {
		const unReadCount = this.state.unReadCount > 99 ? `99+` : this.state.unReadCount;
		return (
			<div className="message-socket">
				<span
					className="message-dll"
					onClick={() => {
						if (this.state.total > 0) {
							this.openMessage();
						}
					}}
				>
					<span className="unread-count">{unReadCount}</span>
					<span className="message-img"></span>
				</span>
				<MessageDrawer
					onRef={(ref: any) => (this.messageRef = ref)}
					{...this.props}
					getDrawerData={this.getDrawerData}
				></MessageDrawer>
				<audio className="msg-audio" ref={(ref: any) => (this.audioRef = ref)} src={messageSound} controls>
					<track kind="captions" />
					您的浏览器不支持 audio 元素。
				</audio>
			</div>
		);
	}
}
const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: void) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageSocket);
