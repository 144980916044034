import './index.less';
import { PublishScopeProps } from './interface';
import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import IotButton from '@/components/IotButton';
import { CloseOutlined } from '@ant-design/icons';
import AreaTree from '@/template/AreaTree';

const PublishScope = (props: PublishScopeProps) => {
	const {
		selectComplete = () => {},
		onChangeTab = () => {},
		allow = ['AGENCY', 'COMPANY', 'OWNER', 'TOURIST'],
		value,
		disabled
	} = props;
	const [activeKey, setActiveKey] = useState<number>(1);
	const [userRows, setUserRows] = useState<any[]>([]);
	const [userRowKeys, setUserRowKeys] = useState<any[]>([]);
	const [noticeValue, setNoticeValue] = useState<any[]>([]);
	const [parkUserRowKeys, setparkUserRowKeys] = useState<any[]>([]);
	const [companyRowCopy, setCompanyRowCopy] = useState<any[]>([]);
	const [ownerRowCopy, setOwnerRowCopy] = useState<any[]>([]);
	const [ownerRow, setOwnerRow] = useState<any[]>([]);
	const [companyRow, setCompanyRow] = useState<any[]>([]);
	const [parkUserRows, setparkUserRows] = useState<any[]>([]);
	const [ownerRowKeys, setOwnerRowKeys] = useState<any[]>([]);
	const [companyRowKeys, setCompanyRowKeys] = useState<any[]>([]);
	const [companyRowKeysCopy, setCompanyRowKeysCopy] = useState<any[]>([]);
	const [ownerRowKeysCopy, setOwnerRowKeysCopy] = useState<any[]>([]);
	const [activeVisitorCopy, setActiveVisitorCopy] = useState(false);
	const [activeVisitor, setActiveVisitor] = useState(false);
	const [showSelectUser, setShowSelectUser] = useState(false);
	const onRemoveSelectUser = (item: any) => {
		const rowResultIndex = userRows.findIndex((it) => it.key === item.key);
		const keyResultIndex = userRowKeys.findIndex((it) => it === item.key);
		userRows.splice(rowResultIndex, 1);
		userRowKeys.splice(keyResultIndex, 1);
		if (userRowKeys?.includes('all')) {
			const idx = userRowKeys.findIndex((item) => item === 'all');
			userRowKeys.splice(idx, 1);
		}
		setUserRows([...userRows]);
		setUserRowKeys([...userRowKeys]);
		setparkUserRows([...userRows]);
		setparkUserRowKeys([...userRowKeys]);
	};
	const onRemoveSelectCompany = (item: any) => {
		const rowResultIndex = companyRow.findIndex((it) => it.key === item.key);
		const keyResultIndex = companyRowKeys.findIndex((it) => it === item.key);
		companyRow.splice(rowResultIndex, 1);
		companyRowKeys.splice(keyResultIndex, 1);
		if (companyRowKeys?.includes('all')) {
			const idx = companyRowKeys.findIndex((item) => item === 'all');
			companyRowKeys.splice(idx, 1);
		}
		setCompanyRow([...companyRow]);
		setCompanyRowKeys([...companyRowKeys]);
		setCompanyRowCopy([...companyRow]);
		setCompanyRowKeysCopy([...companyRowKeys]);
	};
	const onRemoveSelectOwner = (item: any) => {
		const rowResultIndex = ownerRow.findIndex((it) => it.key === item.key);
		const keyResultIndex = ownerRowKeys.findIndex((it) => it === item.key);
		ownerRow.splice(rowResultIndex, 1);
		ownerRowKeys.splice(keyResultIndex, 1);
		if (ownerRowKeys?.includes('all')) {
			const idx = ownerRowKeys.findIndex((item) => item === 'all');
			ownerRowKeys.splice(idx, 1);
		}
		setOwnerRow([...ownerRow]);
		setOwnerRowKeys([...ownerRowKeys]);
		setOwnerRowCopy([...ownerRow]);
		setOwnerRowKeysCopy([...ownerRowKeys]);
	};
	const checkOwnerTree = (e: string[], e2: any) => {
		const { checkedNodes } = e2;
		setOwnerRowKeys(e);
		setOwnerRow(checkedNodes);
	};
	const checkCompanyTree = (e: string[], e2: any) => {
		const { checkedNodes } = e2;
		setCompanyRow(checkedNodes);
		setCompanyRowKeys(e);
	};
	const checkOrgTree = (e: string[], e2: any) => {
		const { checkedNodes } = e2;
		setUserRows(checkedNodes);
		setUserRowKeys(e);
	};
	const renderSelectUsers = () => {
		const result = [...parkUserRows];
		let newResult = [];
		newResult = result.filter((item: any) => {
			return item.key !== 'all';
		});
		const option: any = {
			useCache: false,
			treeNodeType: 'ORG',
			idKey: 'agencyTreeId',
			idParamType: 'body',
			hasAll: true
		};
		return (
			<div className="publish-notice-target-view-body">
				{allow.includes('AGENCY') ? (
					<div className="publish-notice-target-item">
						<h4>园区机构:</h4>
						<div className="publish-notice-target-item-right">
							{newResult.map((it: any, idx: number) => {
								if (it.key === 'all') return null;
								return (
									<div key={idx + ''} className="publish-notice-target-item-right-item">
										{it.title}
										<span
											className="publish-notice-target-item-right-item-close"
											onClick={() => {
												onRemoveSelectUser(it);
											}}
											style={{ display: disabled ? 'none' : '' }}
										>
											X
										</span>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{allow.includes('COMPANY') ? (
					<div className="publish-notice-target-item">
						<h4>企业:</h4>
						<div className="publish-notice-target-item-right">
							{companyRowCopy.map((it: any, idx: number) => {
								if (it.key === 'all') return null;
								return (
									<div key={it.id} className="publish-notice-target-item-right-item">
										{it.name}
										<span
											className="publish-notice-target-item-right-item-close"
											style={{ display: disabled ? 'none' : '' }}
											onClick={() => {
												onRemoveSelectCompany(it);
											}}
										>
											X
										</span>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{allow.includes('OWNER') ? (
					<div className="publish-notice-target-item">
						<h4>居住人员:</h4>
						<div className="publish-notice-target-item-right">
							{ownerRowCopy.map((it: any, idx: number) => {
								if (it.key === 'all') return null;
								return (
									<div key={it.id} className="publish-notice-target-item-right-item">
										{it.name}
										<span
											style={{ display: disabled ? 'none' : '' }}
											className="publish-notice-target-item-right-item-close"
											onClick={() => {
												onRemoveSelectOwner(it);
											}}
										>
											X
										</span>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{allow.includes('TOURIST') ? (
					<div className="publish-notice-target-item">
						<h4>游客:</h4>
						<div className="publish-notice-target-item-right">
							<span>{activeVisitorCopy ? '全部' : ''}</span>
						</div>
					</div>
				) : null}

				{showSelectUser ? (
					<div className="notice-send-select-outer">
						<div className="notice-send-select-user-view publish-send-select">
							<div className="headerTitle">
								<span>接收对象选择</span>
								<span
									className="closeBtn"
									onClick={() => {
										setShowSelectUser(false);
									}}
								>
									<CloseOutlined />
								</span>
							</div>
							<div className="notice-send-select-inner publish-send-select-inner">
								{allow.includes('AGENCY') ? (
									<div className="publish-send-select-inner-left">
										<div className="publish-send-tree-title">园区管理机构</div>
										<div className="publish-send-tree-body">
											<AreaTree
												{...option}
												treeProps={{
													checkable: true,
													onCheck: checkOrgTree,
													checkedKeys: userRowKeys
												}}
											/>
										</div>
									</div>
								) : null}
								{allow.includes('COMPANY') ? (
									<div className="publish-send-select-inner-right">
										<div className="publish-send-tree-title">园区企业机构</div>
										<div className="publish-send-tree-body">
											<AreaTree
												treeNodeType="COMPANYLIST"
												idKey="id"
												key="aaa"
												hasAll={true}
												idParamType="query"
												treeProps={{
													checkable: true,
													onCheck: checkCompanyTree,
													checkedKeys: companyRowKeys
												}}
											/>
										</div>
									</div>
								) : null}
								{allow.includes('OWNER') ? (
									<div className="publish-send-select-inner-right">
										<div className="publish-send-tree-title">居住人员</div>
										<div className="publish-send-tree-body">
											<AreaTree
												treeNodeType="UNIT"
												idKey="devId"
												key="bbb"
												hasAll={true}
												idParamType="query"
												treeProps={{
													checkable: true,
													onCheck: checkOwnerTree,
													checkedKeys: ownerRowKeys
												}}
											/>
										</div>
									</div>
								) : null}
								{allow.includes('TOURIST') ? (
									<div className="publish-send-select-inner-right">
										<div className="publish-send-tree-title">游客</div>
										<div className="publish-send-tree-body visitor-select-all">
											<Checkbox
												checked={activeVisitor}
												onChange={(e) => {
													setActiveVisitor(e.target.checked);
												}}
											>
												全部
											</Checkbox>
										</div>
									</div>
								) : null}
							</div>
							<div className="notice-send-obj-btn">
								<IotButton
									onClick={() => {
										setShowSelectUser(false);
										setparkUserRows([...userRows]);
										setparkUserRowKeys([...userRowKeys]);
										setCompanyRowCopy([...companyRow]);
										setCompanyRowKeysCopy([...companyRowKeys]);
										setOwnerRowCopy([...ownerRow]);
										setOwnerRowKeysCopy([...ownerRowKeys]);
										setActiveVisitorCopy(activeVisitor);
										initFixData();
										const obj: any = {
											AGENCY: userRows,
											COMPANY: companyRow,
											OWNER: ownerRow,
											TOURIST: activeVisitor ? ['TOURIST'] : []
										};
										const result: any = {};
										if (Array.isArray(Object.keys(obj))) {
											Object.keys(obj).map((item: any) => {
												if (allow.includes(item)) {
													if (item === 'TOURIST') {
														result[item] = obj[item];
													} else {
														result[item] = obj[item]
															.filter((it: any) => it.key !== 'all')
															.map((p: any) => {
																return {
																	id: p.key,
																	name: p.name
																};
															});
													}
												}
											});
										}
										selectComplete(result);
									}}
								>
									确定
								</IotButton>
								<IotButton
									onClick={() => {
										setShowSelectUser(false);
									}}
								>
									取消
								</IotButton>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	};
	const initCustomData = () => {
		setUserRows([]);
		setUserRowKeys([]);
		setOwnerRowKeysCopy([]);
		setOwnerRowCopy([]);
		setOwnerRow([]);
		setOwnerRowKeys([]);
		setparkUserRows([]);
		setparkUserRowKeys([]);
		setCompanyRowKeysCopy([]);
		setCompanyRowCopy([]);
		setCompanyRow([]);
		setCompanyRowKeys([]);
		setActiveVisitor(false);
		setActiveVisitorCopy(false);
	};
	const initFixData = () => {
		setNoticeValue([]);
	};
	useEffect(() => {
		if (activeKey === 1) {
			selectComplete(noticeValue);
			initCustomData();
		}
	}, [noticeValue, activeKey]);

	useEffect(() => {
		if (!value) {
			return;
		}
		let obj: any;
		if (typeof value == 'string') {
			obj = JSON.parse(value);
		} else {
			obj = value;
		}
		if (Array.isArray(obj)) {
			setActiveKey(1);
			onChangeTab(1);
			setNoticeValue(obj);
		} else {
			setActiveKey(2);
			onChangeTab(2);
			setOwnerRowKeys(
				obj?.OWNER.map((item: any) => {
					return item.id;
				})
			);
			setOwnerRowKeysCopy(
				obj?.OWNER.map((item: any) => {
					return item.id;
				})
			);
			setOwnerRowCopy(
				obj?.OWNER.map((item: any) => {
					return {
						key: item.id,
						name: item.name,
						title: item.name
					};
				})
			);
			setOwnerRow(
				obj?.OWNER.map((item: any) => {
					return {
						key: item.id,
						name: item.name,
						title: item.name
					};
				})
			);
			setCompanyRow(
				obj?.COMPANY.map((item: any) => {
					return {
						key: item.id,
						name: item.name,
						title: item.name
					};
				})
			);
			setCompanyRowCopy(
				obj?.COMPANY.map((item: any) => {
					return {
						key: item.id,
						name: item.name,
						title: item.name
					};
				})
			);
			setCompanyRowKeys(
				obj?.COMPANY.map((item: any) => {
					return item.id;
				})
			);
			setCompanyRowKeysCopy(
				obj?.COMPANY.map((item: any) => {
					return item.id;
				})
			);

			setUserRows(
				obj?.AGENCY.map((item: any) => {
					return {
						key: item.id,
						name: item.name,
						title: item.name
					};
				})
			);
			setUserRowKeys(
				obj?.AGENCY.map((item: any) => {
					return item.id;
				})
			);
			setparkUserRows(
				obj?.AGENCY.map((item: any) => {
					return {
						key: item.id,
						name: item.name,
						title: item.name
					};
				})
			);
			setparkUserRowKeys(
				obj?.AGENCY.map((item: any) => {
					return item.id;
				})
			);
			setActiveVisitor(value?.TOURIST || []);
			setActiveVisitorCopy(value?.TOURIST || []);
		}
	}, [value]);
	return (
		<div className="publish-notice-target">
			<div className="publish-notice-target-tab" style={{ display: disabled ? 'none' : '' }}>
				<div
					className={`publish-notice-target-tab-btn ${activeKey === 1 ? 'active-publish-btn' : ''}`}
					onClick={() => {
						setActiveKey(1);
						onChangeTab(1);
					}}
				>
					固定接收对象
				</div>
				<div
					className={`publish-notice-target-tab-btn ${activeKey === 2 ? 'active-publish-btn' : ''}`}
					onClick={() => {
						onChangeTab(2);
						setActiveKey(2);
					}}
				>
					自定义
				</div>
			</div>
			<div className="publish-notice-target-body">
				{activeKey === 1 ? (
					<div>
						<Checkbox
							checked={noticeValue.length === allow.length}
							value="all"
							disabled={disabled}
							onChange={() => {
								if (noticeValue.length === allow.length) {
									setNoticeValue([]);
								} else {
									setNoticeValue([...allow]);
								}
							}}
						>
							全部用户
						</Checkbox>
						<Checkbox.Group value={noticeValue} disabled={disabled}>
							{allow.includes('AGENCY') ? (
								<Checkbox
									value="AGENCY"
									onChange={(e) => {
										const idx = noticeValue.findIndex((it) => it === 'AGENCY');
										if (idx === -1) {
											noticeValue.push('AGENCY');
										} else {
											noticeValue.splice(idx, 1);
										}
										setNoticeValue([...noticeValue]);
									}}
								>
									全部园区管理机构
								</Checkbox>
							) : null}
							{allow.includes('COMPANY') ? (
								<Checkbox
									value="COMPANY"
									onChange={(e) => {
										const idx = noticeValue.findIndex((it) => it === 'COMPANY');
										if (idx === -1) {
											noticeValue.push('COMPANY');
										} else {
											noticeValue.splice(idx, 1);
										}
										setNoticeValue([...noticeValue]);
									}}
								>
									全部园区企业
								</Checkbox>
							) : null}
							{allow.includes('OWNER') ? (
								<Checkbox
									value="OWNER"
									onChange={(e) => {
										const idx = noticeValue.findIndex((it) => it === 'OWNER');
										if (idx === -1) {
											noticeValue.push('OWNER');
										} else {
											noticeValue.splice(idx, 1);
										}
										setNoticeValue([...noticeValue]);
									}}
								>
									全部居住人员
								</Checkbox>
							) : null}
							{allow.includes('TOURIST') ? (
								<Checkbox
									value="TOURIST"
									onChange={(e) => {
										const idx = noticeValue.findIndex((it) => it === 'TOURIST');
										if (idx === -1) {
											noticeValue.push('TOURIST');
										} else {
											noticeValue.splice(idx, 1);
										}
										setNoticeValue([...noticeValue]);
									}}
								>
									全部游客
								</Checkbox>
							) : null}
						</Checkbox.Group>
					</div>
				) : null}
				{activeKey === 2 ? (
					<div className="publish-notice-target-view">
						<h3 style={{ display: disabled ? 'none' : '' }}>
							<span>已选对象</span>
							<IotButton
								onClick={() => {
									setShowSelectUser(true);
									setUserRows([...parkUserRows]);
									setUserRowKeys([...parkUserRowKeys]);
									setCompanyRow([...companyRowCopy]);
									setCompanyRowKeys([...companyRowKeysCopy]);
									setOwnerRow([...ownerRowCopy]);
									setOwnerRowKeys([...ownerRowKeysCopy]);
									setActiveVisitor(activeVisitorCopy);
								}}
							>
								选择
							</IotButton>
						</h3>
						{renderSelectUsers()}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PublishScope;
