import doc from './propertyDoc';
const propertyService = {
	getFeedbackList: {
		method: 'POST',
		url: '/smartpark-backand/v1.0.0/feedback/FeedBackList'
	},
	getMultiInfo: {
		method: 'GET',
		url: '/smartpark-backand/v1.0.0/multiinfo/info'
	},
	saveMultiInfo: {
		method: 'POST',
		url: '/smartpark-backand/v1.0.0/multiinfo/save'
	},
	...doc
};
export default propertyService;
