const companyManagement = {
	companyList: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfenterprise/list'
	},
	saveCompany: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfenterprise/saveAndapproval'
	},
	updateCompany: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfenterprise/update'
	},
	deleteCompany: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfenterprise/delete'
	},
	checkCompany: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfenterprise/approval'
	},
	typeList: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bftradeorg/list'
	},
	saveType: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bftradeorg/batchSave'
	},
	deleteType: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bftradeorg/delete'
	},
	updateType: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bftradeorg/update'
	}
};
export default companyManagement;
