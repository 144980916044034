const AreaBaseInfo = {
	getAreaBaseInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/areabaseinfo/getOrcadtAreaBaseInfo/'
	},
	getWorkPeopleBaseInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/areabaseinfo/getWorkPeopleBaseInfo/'
	},
	getWorkTimeBaseInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/areabaseinfo/getWorkTimeBaseInfo/'
	},
	saveAreaBaseInfo: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/areabaseinfo/saveAreaBaseInfo'
	},
	saveWorkPeopleBaseInfo: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/areabaseinfo/saveWorkPeopleBaseInfo'
	},
	saveWorkTimeBaseInfo: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/areabaseinfo/saveWorkTimeBaseInfo'
	},
	deleteWorkPeople: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/areabaseinfo/deleteWorkPeople'
	},
	saveParkInfo: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/parkarea/saveParkInfo'
	},
	getParkInfo: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/parkarea/getParkInfo'
	},
	getEnterprisePermission: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/enterprise/getEnterprisePermission'
	}
};

export default AreaBaseInfo;
