const BroadCast = {
	getBroadCastList: {
		method: 'POST',
		url: '/smartparksysgeneral/v1.0.0/broadcast/page'
	},
	addBroadCast: {
		method: 'POST',
		url: '/smartparksysgeneral/v1.0.0/broadcast/save'
	},
	deleteBroadCast: {
		method: 'DELETE',
		url: '/smartparksysgeneral/v1.0.0/broadcast/'
	}
};
export default BroadCast;
