import React from 'react';
import ReactDOM from 'react-dom';

//基础样式
import './static/fonts/font.css';
import './static/css/vars.css';
import 'reset-css';
import './index.less';

//路由routerConfig
import { HashRouter as Router } from 'react-router-dom';
import routes from '@/router';
import { renderRoutes } from 'react-router-config';

//Redux
import { Provider } from 'react-redux';
import store from './store';

//测试页面性能插件
import reportWebVitals from './reportWebVitals';

//antd
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
ReactDOM.render(
	<ConfigProvider locale={zhCN}>
		<Provider store={store}>
			<Router>{renderRoutes(routes)}</Router>
		</Provider>
	</ConfigProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
