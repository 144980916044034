import React, { Component } from 'react';
import { Drawer, Button, Pagination } from 'antd';
import { Props, State } from './interface';
import { General } from '../../api';
import { storage } from '../../utils';
import './index.less';
class MessageDrawer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			visible: false,
			detailVisible: false,
			dataList: [],
			currentRecord: {},
			total: 0,
			unReadCount: 0,
			pageNum: 1,
			pageSize: 5,
			from: ''
		};
	}
	componentDidMount() {
		this.props.onRef && this.props.onRef(this);
	}
	componentWillUnmount() {
		this.setState = (state: any, callback: any) => {
			return;
		};
	}
	open = (cb?: Function) => {
		this.setState(
			{
				visible: true
			},
			() => {
				cb && cb();
			}
		);
	};
	close = () => {
		this.setState({
			visible: false,
			pageNum: 1
		});
	};
	openDetail = (record: any, cb?: Function) => {
		this.setState(
			{
				detailVisible: true,
				currentRecord: record,
				from: ''
			},
			() => {
				cb && cb();
			}
		);
	};

	fetchData = (callback?: Function) => {
		const body: any = {
			pageNum: this.state.pageNum,
			pageSize: this.state.pageSize,
			userId: storage.getSession('appId'),
			sendType: '01',
			messageTypes: ['company_report_110'],
			devPermission: false,
			uIPermission: false
			// messageTypes: ['MUCK_ALARM', 'SUSPECTED_DUMP', 'WARNING_NOTICE']
		};
		General.queryInfoListPagePost({
			body
		}).then((res: any) => {
			if (res.code === 0) {
				callback && callback(res);
			}
		});
	};

	init = () => {
		this.fetchData((res: any) => {
			if (Array.isArray(res.data.list)) {
				this.setState({
					total: res.data.total,
					unReadCount: res.data.unReadCount,
					dataList: res.data.list
				});
				this.props.getDrawerData && this.props.getDrawerData(res?.data?.total, res?.data?.unReadCount);
			}
		});
	};

	detailClose = () => {
		this.setState({
			detailVisible: false
		});
	};
	viewDetail = async (record: any, from: string) => {
		const currentRecord: any = record;
		if (record.messageType === 'NOTICE') {
			const result = await General.getNoticeInfo({
				path: [record.customerParams.id]
			});
			if (result.data) {
				const { content = '' } = result.data;
				currentRecord.notice = content;
			}
		}
		this.setState({
			detailVisible: true,
			currentRecord,
			from: from
		});
		this.updateToRead(record);
	};

	updateToRead = async (record: any) => {
		//* 通知公告
		if (record.messageType === 'NOTICE') {
			const { id } = record.customerParams;
			const result = await General.readNotice({
				body: [id]
			});
			if (result.code === 0) {
				this.props.initReadCount && this.props.initReadCount();
				this.init();
			}
		} else {
			const result = await General.updateToRead({
				path: [record.id]
			});
			if (result.code === 0) {
				this.props.initReadCount && this.props.initReadCount();
				this.init();
			}
		}
	};

	onPaginationChange = (page: any) => {
		this.setState(
			{
				pageNum: page
			},
			this.init
		);
	};

	handleMessage = (data?: any) => {
		console.log(data, 'data');
		// if (this.props.location.pathname !== '/upcomingTasks') {
		// 	storage.setSession('locationPathname', this.props.location.pathname);
		// }
		let customerParams: any = {};
		let messageType: any = '';
		if (data) {
			customerParams = data.customerParams;
			messageType = data.messageType;
		} else {
			customerParams = this.state.currentRecord.customerParams;
			messageType = this.state.currentRecord.messageType;
		}
		const handleUrl = (url: string) => {
			if (customerParams && (customerParams.processInstanceId || customerParams.id)) {
				return `${url}?id=${customerParams.processInstanceId || customerParams.id}`;
				// return `${url}?from=${messageType}&id=${customerParams.processInstanceId || customerParams.id}`;
			} else {
				return `${url}?from=${messageType}`;
			}
		};
		const messageTypeEnum: any = {
			company_report_110: handleUrl('/systemIndex/companyServer/company') //企业服务上报
		};
		if (messageType === 'NOTICE') {
			this.viewDetail(this.state.currentRecord, '');
			return;
		}
		storage.setSession('customerParams', customerParams);
		storage.setSession('locationPathname', this.props.location.pathname);
		let route: any = '';
		// if (taskMessage.includes(messageType)) {
		// 	route = handleUrl('/upcomingTasks');
		// 	this.props.history.push(route);
		// } else {
		// 	route = messageTypeEnum[messageType];
		// 	this.props.history.push(route);
		// }
		route = messageTypeEnum[messageType];
		this.props.history.push(route);
		if (data) {
			this.updateToRead(data);
		} else {
			this.updateToRead(this.state.currentRecord);
		}
		this.setState({
			detailVisible: false,
			visible: false
		});
	};
	render() {
		const {
			unReadCount = 0,
			total = 0,
			pageNum = 1,
			currentRecord = {},
			detailVisible,
			visible,
			dataList = []
		} = this.state;
		const title = `消息列表（${unReadCount}/${total}）[未读/全部]`;
		const detailEnum: AnyObject = {
			sender: '发送人',
			module: '所属功能',
			sendTime: '发送时间',
			status: '当前状态'
		};
		return (
			<div>
				<Drawer
					title={title}
					placement="right"
					className="message-drawer"
					closable={false}
					onClose={this.close}
					visible={visible}
					width={360}
					zIndex={10001}
				>
					<div className="message-list">
						{dataList.map((item: any) => {
							return (
								<div key={`${item.id}${Math.random()}`} className="message-item">
									<div className="message-content">
										<span
											className="message-content-inner "
											onClick={() => {
												this.viewDetail(item, '');
											}}
										>
											{typeof item.content === 'string' ? (
												<span>
													<b style={{ color: '#CD7D44' }}>
														{item.content.match(/(^【.+?】)+/g)}
													</b>
													<b>{item.content.replace(/(^【.+?】)+/g, '')}</b>
												</span>
											) : (
												''
											)}
										</span>
										<span
											className="message-content-go"
											onClick={() => {
												this.setState(
													{
														currentRecord: item
													},
													this.handleMessage
												);
											}}
										>
											{item.messageType === 'NOTICE' ? '前往查看' : '前往处理'}
											&gt;
										</span>
									</div>
									<div className="message-desc">
										<span>{item.receiveTime || item.sendTime}</span>
										<span className={`${item.status === 0 ? 'unread' : 'read'} desc-img`}></span>
									</div>
								</div>
							);
						})}
						<div className="message-pagination">
							<Pagination
								defaultCurrent={1}
								current={pageNum}
								size="small"
								total={total}
								showQuickJumper={true}
								showSizeChanger={false}
								pageSize={5}
								onChange={this.onPaginationChange}
							/>
						</div>
					</div>
				</Drawer>
				<Drawer
					title=""
					width={360}
					closable={false}
					onClose={this.detailClose}
					visible={detailVisible}
					className="message-detail"
					zIndex={10002}
				>
					<div className="detail-content">
						<div className="detail-line"></div>
						<div className="detail-title">{currentRecord.title}</div>
						<div className="detail-desc-list">
							{Object.keys(detailEnum).map((key: string) => {
								const detailValue = currentRecord[key];
								const detailStatus = ['已读', '已读'];
								return (
									<div className="detail-item" key={key}>
										<span className="detail-label">{detailEnum[key]}</span>
										<span className="detail-content">
											{key === 'status' ? detailStatus[detailValue] : detailValue}
										</span>
									</div>
								);
							})}
						</div>
						<div className="message-content">
							<span>消息内容： </span>
							<span>
								{currentRecord.messageType === 'NOTICE'
									? currentRecord.notice || '无'
									: currentRecord.content}
							</span>
						</div>
						<div className="footer-action">
							{currentRecord.messageType === 'NOTICE' ? null : (
								<Button
									type="primary"
									style={{ marginRight: 20 }}
									onClick={() => {
										this.handleMessage();
									}}
								>
									前往处理
								</Button>
							)}
							<Button
								type="default"
								onClick={() => {
									this.setState({
										currentRecord: {},
										detailVisible: false,
										visible: !this.state.from
									});
								}}
							>
								收回
							</Button>
						</div>
					</div>
				</Drawer>
			</div>
		);
	}
}

export default MessageDrawer;
