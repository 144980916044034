import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Props } from './interface';
import './index.less';
import { Timeline, Tooltip } from 'antd';
import { SecurityWarning } from '@/api';
import { storage } from '@/utils';
import ImageView from '@/components/ImgView';
const HistoryInfo = (props: Props, ref: any) => {
	// @ts-ignore
	const userId = storage.getSession('userId');
	// @ts-ignore
	const companyId = storage.getSession('login').companyId;
	const [historyList, setHistoryList] = useState<AnyObject[]>([]);
	const [copyHistoryList, setCopyHistoryList] = useState<AnyObject[]>([]);
	const { current } = props;
	useEffect(() => {
		Object.keys(current).length && initData();
	}, [current]);
	const { processVariables } = current;
	const initData = () => {
		SecurityWarning.getTaskHistory({
			query: {
				asc: true,
				//@ts-ignore
				tenantId: storage.getSession('login').companyId,
				processInstanceId: current.processInstanceId
			}
		})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					if (Array.isArray(res.data)) {
						setCopyHistoryList(res.data);
						const result: any[] = [];
						res.data.map((item: any, index: number) => {
							if (item.name === 'REPAIRORDERINFO') {
								result.push({
									label: '提交',
									time: current?.startTime || '',
									dealName: '上报人',
									user: processVariables?.companyName || ''
								});
							} else if (item.name === '中心处理' || item.name === '部门处理') {
								const { formProperties = [] } = item;
								const dealName = formProperties.find((it: any) =>
									item.name === '中心处理' ? it.name === '处理详情' : it.name === '部门处理详情'
								);
								const image = formProperties.find((it: any) =>
									item.name === '中心处理'
										? it.name === '处理图片视频资源'
										: it.name === '部门处理图片视频'
								);
								const file = formProperties.find((it: any) =>
									item.name === '中心处理' ? it.name === '处理附件' : it.name === '部门处理附件'
								);
								result.push({
									label: item.name,
									dealName: '处理人',
									time: item.endTime,
									desc: () => {
										return dealName?.value ? (
											<>
												<div>处理详情: {dealName?.value}</div>
												{file && file.value ? (
													<div key={index} className="history_file">
														<div className="file_label">附件:</div>
														{file.value.split(',').map((item: any, index: number) => {
															return (
																<div
																	className="file-item"
																	key={index}
																	onClick={() => {
																		downLoadHelpDoc(item);
																	}}
																>
																	<Tooltip placement="top" title={item}>
																		{item}
																	</Tooltip>
																</div>
															);
														})}
													</div>
												) : null}
												{image && image?.value ? (
													<div key={index} className="_video_image">
														<div className="_video_title">图片视频:</div>
														{image.value.split(',').map((item: any, idx: number) => {
															const isVideo =
																item &&
																(item.indexOf('mp4') > -1 ||
																	item.indexOf('rmvb') > -1 ||
																	item.indexOf('avi') > -1);
															return (
																<div key={idx} className="time_line_images_item">
																	{isVideo ? (
																		<video
																			className="time_line_images_item"
																			style={{
																				width: '60px',
																				height: '60px'
																			}}
																			src={`/smartparksysgeneral/v1.0.0/file/download?appId=${userId}&companyId=${companyId}&filename=${item}`}
																			controls={true}
																			controlsList="nodownload"
																		></video>
																	) : (
																		<div
																			className="images_item"
																			style={{
																				width: '30px',
																				height: '30px'
																			}}
																			key={index}
																		>
																			<ImageView
																				image={item}
																				imageType={false}
																			></ImageView>
																		</div>
																	)}
																</div>
															);
														})}
													</div>
												) : null}
											</>
										) : null;
									},
									user: item.assigner
								});
							} else if (item.name === '客户评价') {
								const { formProperties = [] } = item;
								const questionDetail = formProperties.find(
									(items: any) => items.key === 'questionDetail'
								);
								const isSatisfy = formProperties.find((items: any) => items.name === '是否满意');
								const evaluator = formProperties.find((items: any) => items.key === 'evaluator');
								const renderLabel = (
									<>
										<div>评价</div>
										<div>评价人 : {evaluator ? evaluator.value : ''}</div>
										<div>评价 : {isSatisfy.value === 'true' ? '满意' : '不满意'}</div>
										{questionDetail ? <div>继续提问 : {questionDetail.value}</div> : null}
									</>
								);
								result.push({
									label: renderLabel,
									time: item.endTime
								});
							}
						});
						if (current?.endTime) {
							result.push({
								label: '结束',
								time: current?.endTime || ''
							});
						}
						setHistoryList(result);
					}
				}
			})
			.catch((err: IotResponse) => {
				throw err.msg;
			});
	};
	useImperativeHandle(ref, () => {
		return { copyHistoryList };
	});
	const downLoadHelpDoc = (data: any) => {
		// @ts-ignore
		const appId = storage.getSession('appId');
		// @ts-ignore
		const companyId = storage.getSession('login').companyId;
		const domainName = window.location.origin;
		window.open(
			`${domainName}/smartparksysgeneral/v1.0.0/file/download?appId=${appId}&companyId=${companyId}&filename=${data}`
		);
	};
	return (
		<Fragment>
			<Timeline mode="left">
				{historyList.map((item: any, index: number) => {
					return (
						<Timeline.Item key={index} label={item.time}>
							<div className="history-flow-values">
								<span>{item.label}</span>
								<div>
									{item.dealName && ` ${item.dealName} : ${item.user}`}
									{item.label !== '结束' ? <div> {item.desc && item.desc()}</div> : null}
								</div>
							</div>
						</Timeline.Item>
					);
				})}
			</Timeline>
		</Fragment>
	);
};
export default forwardRef(HistoryInfo);
