import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Props } from './interface';
import './index.less';
import { message, Tooltip, Button, Radio, Checkbox, Spin, DatePicker, Rate, Image, Divider, Input, Modal } from 'antd';
import IotImgUpload from '@/components/IotImgUpload';
import { DeleteOutlined } from '@ant-design/icons';
import ImageView from '@/components/ImgView';
import { storage } from '@/utils';
import HistoryInfo from '@/module/historyInfo';
import { Management, SecurityWarning, Task } from '@/api';
import moment from 'moment';
import IotModal from '@/components/IotModal';
import { RadioChangeEvent } from 'antd/lib/radio';
import BaseUser from './baseUser';
const { TextArea } = Input;
let ModalRef: any;
const CompanyDetail = (props: Props) => {
	const baseUserRef = useRef(null);
	const [transferName, setTransferName] = useState<string>('');
	const [transferId, setTransferId] = useState<any>('');
	const [fileList, setFileList] = useState<AnyObject[]>([]);
	const [imageList, setImageList] = useState<AnyObject[]>([]);
	const { current = {}, currentId = '', linkFrom = '' } = props;
	const [dealInfo, setDealInfo] = useState<string>('');
	const [baseFiles, setBaseFiles] = useState<any[]>([]);
	const [baseImage, setBaseImage] = useState<any[]>([]);
	const [isTransfer, setIsTransfer] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [isSatisfy, setIsSatisfy] = useState<boolean>(true);
	const [isSubmit, setIsSubmit] = useState<boolean>(true);
	const [questionDetail, setQuestionDetail] = useState<string>('');
	const [isQuestion, setIsQuestion] = useState<boolean>(false);
	const [historyInfoRef, setHistoryInfoRef] = useState<any[]>([]);
	const [processVariables, setProcessVariables] = useState<AnyObject>({});
	const [currentData, setCurrentData] = useState<AnyObject>({});
	const [pageStatus, setPageStatus] = useState<string>('');
	const [selectRowKeys, setSelectRowKeys] = useState<any[]>([]);
	const [historicTaskInstance, setHistoricTaskInstance] = useState<any[]>([]);
	// historicTaskInstance
	const [baseInfo, setBaseInfo] = useState<AnyObject>({
		companyName: '',
		detail: '',
		companyLinkMan: '',
		companyTelephone: '',
		accessory: '',
		resource: '',
		reportTime: '',
		startTime: '',
		timeStatus: ''
	});
	const extraBox = {
		multiple: false,
		accept: '.jpg,.png,.jpeg,.gif,.mp4,.avi,.mkv,.wmv,.m3u8,.rmvb',
		uploadType: 'media',
		limitSize: 50,
		isCustomRender: true
	};
	// @ts-ignore
	const realName = storage.getSession('login').realName;
	// @ts-ignore
	const userId = storage.getSession('userId');
	// @ts-ignore
	const companyId = storage.getSession('login').companyId;
	const appId = storage.getSession('appId');
	useEffect(() => {
		if (currentId) {
			setLoading(true);
			Task.getNotificationTask({
				body: {
					pageNum: 1,
					pageSize: 1,
					processInstanceId: currentId,
					separatorValueQueries: [],
					tenantId: companyId
				}
			})
				.then((res: any) => {
					if (res.code === 0) {
						setLoading(false);
						const {
							data: { content = [] }
						} = res;
						if (content.length === 0) {
							Modal.info({
								title: '温馨提示:',
								content: (
									<div>
										<p>该事件已不存在!</p>
									</div>
								),
								onOk: () => {
									const path = storage.getSession('locationPathname');
									props.history.replace(path);
									props.onHideForm && props.onHideForm();
								}
							});
						}
						const [result] = content;
						const baseFiles = result.processVariables?.accessory
							? result.processVariables?.accessory.split(',')
							: [];
						const baseResource = result.processVariables?.resource
							? result.processVariables?.resource.split(',')
							: [];
						const dealFiles = result.processVariables.dealAccessory
							? result.processVariables.dealAccessory?.split(',')
							: [];
						const dealResources = result.processVariables.dealResource
							? result.processVariables.dealResource?.split(',')
							: [];
						const { dealDetail, isQuestion = false } = result.processVariables;
						if (!isQuestion && result.processVariables.status !== '0') {
							setDealInfo(dealDetail);
							setFileList(dealFiles);
							setImageList(dealResources);
						}
						const baseInfoData = { ...baseInfo };
						for (const key in baseInfoData) {
							if (`${result.processVariables[key]}`) {
								baseInfoData[key] = result.processVariables[key];
							} else {
								result.processVariables[key] = '';
							}
						}
						baseInfoData.startTime = result.startTime;
						if (result.processVariables.status === '2') setIsSatisfy(result.processVariables.isSatisfy);
						if (linkFrom === 'INFO') {
							setCurrentData(result);
							if (result.historicTaskInstance.length && result.historicTaskInstance[0].candidateUsers) {
								if (result.historicTaskInstance[0].candidateUsers.includes(appId)) {
									setPageStatus('edit');
								} else {
									setPageStatus('view');
								}
							} else {
								setPageStatus('view');
							}
						} else {
							setPageStatus(props.pageStatus);
							setCurrentData(current);
						}
						setBaseInfo(baseInfoData);
						setBaseFiles(baseFiles);
						setBaseImage(baseResource);
						setProcessVariables(result.processVariables);
						setHistoricTaskInstance(result.historicTaskInstance);
					} else {
						setLoading(false);
					}
				})
				.catch((error: any) => {
					setLoading(false);
				});
		} else {
			setPageStatus('add');
		}
	}, [props]);
	const downLoadHelpDoc = (data: any) => {
		// @ts-ignore
		const appId = storage.getSession('appId');
		const domainName = window.location.origin;
		window.open(
			`${domainName}/smartparksysgeneral/v1.0.0/file/download?appId=${appId}&companyId=${companyId}&filename=${data}`
		);
	};
	const renderDealNode = (disabled: boolean, type: number) => {
		const { dealDetail } = processVariables;
		let fileData: any[] = [];
		let imageData: any[] = [];
		let detail = '';
		let showQuestion = false;
		if (disabled) {
			if (historyInfoRef.length) {
				const c = [...historyInfoRef].reverse();
				c.map((item) => {
					const data: any = {};
					item.formProperties.map((it: any) => {
						data[it.key] = it.value;
					});
					item.data = data;
					return item;
				});
				if (c[0].name === '客户评价' || (c[0].data.isTransfer && c[0].data.isTransfer === 'true')) {
					showQuestion = true;
				}
				const d = c.find((it) => {
					if (it.data.orgDealName) return it;
					if (it.data.dealName) return it;
				});
				if (d && d.name === '部门处理') {
					const { orgDealAccessory = '', orgDealDetail = '', orgDealResource = '' } = d.data;
					fileData = orgDealAccessory ? orgDealAccessory.split(',') : [];
					imageData = orgDealResource ? orgDealResource.split(',') : [];
					detail = orgDealDetail;
				}
				if (d && d.name === '中心处理') {
					const { dealAccessory = '', dealResource = '', dealDetail = '' } = d.data;
					fileData = dealAccessory ? dealAccessory.split(',') : [];
					imageData = dealResource ? dealResource.split(',') : [];
					detail = dealDetail;
				}
			}
		} else {
			fileData = fileList;
			imageData = imageList;
			detail = '';
		}
		return (
			<>
				<div className="title">处理</div>
				{disabled && (
					<div className="content_item deal">
						<div className="item_info">
							<div className="label">处理人</div>
							<div className="value">{processVariables?.dealName}</div>
						</div>
						<div className="item_info">
							<div className="label">处理时间</div>
							<div className="value">{processVariables?.dealTime}</div>
						</div>
					</div>
				)}
				{processVariables.node === '中心处理' && !disabled ? (
					<div className="special_item deal">
						<div className="label require_icon">任务处理方式</div>
						<div className="detail_info">
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									setIsTransfer(e.target.value);
								}}
								value={isTransfer}
							>
								<Radio value={false}>自行处理</Radio>
								<Radio value={true}>责任部门处理</Radio>
							</Radio.Group>
						</div>
					</div>
				) : null}
				{isTransfer && !disabled ? (
					<>
						<div className="special_item deal">
							<div className="label require_icon">任务处置人</div>
							<div className="detail_info task-handler">
								<Input
									disabled
									style={{ width: '203px', marginRight: '20px' }}
									value={transferName}
								></Input>
								<Button type="primary" onClick={choosePerson}>
									选择
								</Button>
							</div>
						</div>
						<div className="special_item deal">
							<div className="label require_icon">流转详情</div>
							<div className="detail_info">
								<TextArea
									disabled={disabled}
									rows={4}
									placeholder="请输入内容"
									value={disabled ? dealDetail : dealInfo}
									onChange={(e: any) => {
										setDealInfo(e.target.value);
									}}
								/>
							</div>
						</div>
					</>
				) : null}
				{disabled || (!isTransfer && !disabled) ? (
					<>
						<div className="special_item deal">
							<div className="label require_icon">处理详情</div>
							<div className="detail_info">
								<TextArea
									disabled={disabled}
									rows={4}
									placeholder="请输入内容"
									value={disabled ? detail : dealInfo}
									onChange={(e: any) => {
										setDealInfo(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="special_item deal">
							<div className="label ">附件（{fileData.length}/9）</div>
							<div className="file_info">
								<div>
									{fileData.map((item: any, index: number) => {
										return (
											<div className="deal_file" key={index}>
												<div
													className="file-item"
													onClick={() => {
														downLoadHelpDoc(item);
													}}
												>
													{item}
												</div>
												<span
													className="combination-text-btn combination-text-add"
													onClick={() => {
														const combinationList = [...fileList];
														combinationList.splice(index, 1);
														setFileList(combinationList);
													}}
												>
													{disabled ? null : <DeleteOutlined style={{ fontSize: '18px' }} />}
												</span>
											</div>
										);
									})}
								</div>
								{fileData.length < 9 && pageStatus === 'edit' && !disabled ? (
									<IotImgUpload
										defaultImgUrl={''}
										disabled={false}
										getUploadImage={(file: any) => {
											if (file) {
												const combinationList = [...fileList];
												combinationList.push(file);
												setFileList(combinationList);
											}
										}}
										multiple={false}
										uploadType="file"
										limitSize={9}
										isCustomRender={true}
									></IotImgUpload>
								) : null}
							</div>
						</div>
						<div className="special_item deal">
							<div className="label">图片/视频（{imageData.length}/6）</div>
							<div className="image_info">
								{imageData.map((item: any, idx: number) => {
									const isVideo =
										item &&
										(item.indexOf('mp4') > -1 ||
											item.indexOf('rmvb') > -1 ||
											item.indexOf('avi') > -1);
									return (
										<div key={idx}>
											{isVideo ? (
												<>
													<div
														style={{ width: '120px', height: '120px' }}
														className="task-combination-video"
													>
														<video
															style={{ width: '120px', height: '120px' }}
															src={`/smartparksysgeneral/v1.0.0/file/download?appId=${userId}&companyId=${companyId}&filename=${item}`}
															controls={true}
															controlsList="nodownload"
														></video>
													</div>
													<div className="company-video-title">
														<Tooltip
															className="company-video-tip-ellipsis"
															placement="top"
															title={item}
														>
															{item || '--'}
														</Tooltip>
														<div
															className="company-video-text"
															onClick={() => {
																const combinationList = [...imageList];
																combinationList.splice(idx, 1);
																setImageList(combinationList);
															}}
														>
															{disabled ? null : (
																<DeleteOutlined style={{ fontSize: '18px' }} />
															)}
														</div>
													</div>
												</>
											) : (
												<div className="task-opt-center-img-box task-combination-box">
													<div
														style={{
															width: '120px',
															height: '120px',
															marginRight: '30px'
														}}
													>
														<ImageView image={item} imageType={false}></ImageView>
													</div>
													<div className="company-video-title">
														<div className="company-video-tip-ellipsis">
															<Tooltip placement="top" title={item}>
																{item || '--'}
															</Tooltip>
														</div>
														<span
															className="combination-text-btn combination-text-add"
															onClick={() => {
																const combinationList = [...imageList];
																combinationList.splice(idx, 1);
																setImageList(combinationList);
															}}
														>
															{disabled ? null : (
																<DeleteOutlined
																	style={{ fontSize: '18px', marginTop: '5px' }}
																/>
															)}
														</span>
													</div>
												</div>
											)}
										</div>
									);
								})}
								{imageData.length < 6 && pageStatus === 'edit' && !disabled ? (
									<IotImgUpload
										defaultImgUrl={''}
										disabled={false}
										getUploadImage={(file: any) => {
											if (file) {
												const combinationList = [...imageList];
												combinationList.push(file);
												setImageList(combinationList);
											}
										}}
										{...extraBox}
									></IotImgUpload>
								) : null}
							</div>
						</div>
					</>
				) : null}

				{showQuestion ? evaluation(true) : null}
			</>
		);
	};
	const saveDealInfo = () => {
		if (pageStatus === 'add') {
			getDeployment();
			return;
		}
		let data: any = {};
		if (Number(processVariables.status) === 1) {
			data = {
				isSatisfy,
				evaluationTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
				isQuestion,
				evaluator: realName,
				evaluatorId: storage.getSession('appId')
			};
			if (isQuestion) {
				if (!questionDetail) return message.info('请输入提问内容');
				data.questionDetail = questionDetail;
				data.status = '0';
			} else {
				data.node = '-';
				data.status = '2';
			}
		} else if (processVariables.node === '中心处理') {
			if (isTransfer && !transferId) return message.info('请选择任务处置人');
			if (!dealInfo) return message.info('请输入处理详情');
			data = {
				dealDetail: dealInfo,
				dealName: realName,
				dealTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
				isTransfer,
				dealId: storage.getSession('appId')
			};

			if (isTransfer) {
				data.isTransfer = true;
				data.transferName = transferName;
				data.transferId = transferId;
			} else {
				data.isTransfer = false;
			}
			data['dealAccessory'] = fileList.length ? fileList.join(',') : '';
			data['dealResource'] = imageList.length ? imageList.join(',') : '';
		} else {
			if (!dealInfo) return message.info('请输入处理详情');
			data = {
				orgDealDetail: dealInfo,
				orgDealName: realName,
				orgDealTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
				orgDealId: storage.getSession('appId')
			};
			data['orgDealAccessory'] = fileList.length ? fileList.join(',') : '';
			data['orgDealResource'] = imageList.length ? imageList.join(',') : '';
		}
		if (!isSubmit) return;
		setIsSubmit(false);
		SecurityWarning.submitTask({
			body: {
				userName: storage.getSession('appId'),
				//@ts-ignore
				tenantId: storage.getSession('login').companyId,
				taskId: historicTaskInstance[0] ? historicTaskInstance[0].id : '',
				formData: data
			}
		})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					setIsSubmit(true);
					message.success(res.msg);
					if (linkFrom === 'INFO') {
						const path = storage.getSession('locationPathname');
						props.history.replace(path);
					}
					props.refreshList && props.refreshList();
				} else {
					setIsSubmit(true);
					message.error(res.msg);
				}
			})
			.catch((err: IotResponse) => {
				setIsSubmit(true);
				message.error(err.msg);
			});
	};
	const getDeployment = () => {
		if (!isSubmit) return;
		setIsSubmit(false);
		Management.getDeploymentInfoList({
			query: {
				// @ts-ignore
				tenantId: storage.getSession('login').companyId,
				category: 'qinhanServicePlatform'
			}
		})
			.then((res: any) => {
				if (res.code === 0) {
					const {
						data: { list = [] }
					} = res;
					const [result] = list;
					addRecord(result);
				} else {
					setIsSubmit(true);
				}
			})
			.catch((error: any) => {
				setIsSubmit(true);
			});
	};
	const addRecord = (result: any) => {
		const { companyLinkMan, companyTelephone, detail } = baseInfo;
		if (!companyLinkMan) return message.info('请输入联系人');
		if (!companyTelephone) return message.info('请输入联系电话');
		if (!detail) return message.info('请输入企业诉求');
		const { deployId = '' } = result;
		const appId = storage.getSession('appId');
		const telReg = /^1[345789]\d{9}$/;
		if (!telReg.test(companyTelephone)) return message.info('请输入正确的联系电话');
		Management.createProcessInstanceList({
			body: {
				deploymentId: deployId,
				originator: appId,
				// @ts-ignore
				tenantId: storage.getSession('login').companyId,
				variables: {
					companyName: baseInfo.companyName,
					reportTime: moment().format('YYYY-MM-DD HH:mm:ss'),
					resource: baseImage.length ? baseImage.join(',') : '',
					accessory: baseFiles.length ? baseFiles.join(',') : '',
					detail: baseInfo.detail,
					userId: appId,
					companyLinkMan: baseInfo.companyLinkMan,
					companyTelephone: baseInfo.companyTelephone,
					reportType: 'COMPANY_REPORT'
				}
			}
		})
			.then((res: any) => {
				if (res.code === 0) {
					message.success('新增成功！');
					props.refreshList && props.refreshList();
				} else {
					setIsSubmit(true);
					message.error('新增失败！');
				}
			})
			.catch((error: any) => {
				setIsSubmit(true);
			});
	};
	const baseInfoValue = (e: AnyObject, key: string) => {
		setBaseInfo({
			...baseInfo,
			[key]: e.target.value
		});
	};
	const choosePerson = () => {
		ModalRef?.open();
	};
	const closeModal = () => {};
	const onOk = () => {
		// @ts-ignore
		if (baseUserRef?.current?.selectRows.length === 0) {
			return message.info('请选择人员');
		}
		// @ts-ignore
		setSelectRowKeys(baseUserRef?.current?.selectRows);
		// @ts-ignore
		setTransferName(baseUserRef?.current?.selectRows[0].realName);
		// @ts-ignore
		setTransferId(baseUserRef?.current?.selectRows[0].name);
		ModalRef?.close();
	};
	const evaluation = (disabled: boolean) => {
		const isSatisfyData = disabled ? processVariables.isSatisfy : isSatisfy;
		const isQuestionData = disabled ? processVariables.isQuestion : isQuestion;
		const questionDetailData = disabled ? processVariables.questionDetail : questionDetail;
		return (
			<>
				<div className="title">评价</div>
				<div className="special_item deal">
					<div className="label require_icon">评价</div>
					<div className="detail_info">
						<Radio.Group
							onChange={(e: RadioChangeEvent) => {
								setIsSatisfy(e.target.value);
							}}
							value={isSatisfyData}
							disabled={disabled}
						>
							<Radio value={true}>满意</Radio>
							<Radio value={false}>不满意</Radio>
						</Radio.Group>
					</div>
				</div>
				<div className="special_item deal">
					{processVariables.status === '2' && !processVariables.isQuestion ? null : (
						<div className="label">
							<Checkbox
								disabled={disabled}
								defaultChecked={isQuestionData}
								onChange={(e: any) => {
									setIsQuestion(e.target.checked);
								}}
							>
								继续提问
							</Checkbox>
						</div>
					)}
					{isQuestionData && (
						<div className="detail_info">
							<TextArea
								rows={4}
								disabled={disabled}
								placeholder="请输入内容"
								value={questionDetailData}
								onChange={(e: any) => {
									setQuestionDetail(e.target.value);
								}}
							/>
						</div>
					)}
				</div>
			</>
		);
	};
	return (
		<Fragment>
			<div className="company_server_detail">
				<div className="left">
					{loading ? (
						<div className="example">
							<Spin size="large" tip="Loading..." />
						</div>
					) : (
						<>
							<div className="title">基本信息</div>
							<div className="base_content">
								<div className="content_item">
									<div className="item_info">
										<div className="label ">企业名称</div>
										<div className="value">
											<Input
												disabled={pageStatus !== 'add'}
												value={baseInfo?.companyName}
												onChange={(e: AnyObject) => {
													baseInfoValue(e, 'companyName');
												}}
											/>
										</div>
									</div>
								</div>
								{pageStatus === 'add' ? null : (
									<div className="content_item">
										<div className="item_info">
											<div className="label">提交时间</div>
											<div className="value">
												<Input disabled value={baseInfo?.startTime} />
											</div>
										</div>
										<div className="item_info">
											<div className="label">处理时限</div>
											<div className="value">
												<Input disabled value={['未逾期', '已逾期'][baseInfo?.timeStatus]} />
											</div>
										</div>
									</div>
								)}
								<div className="content_item">
									<div className="item_info">
										<div className="label require_icon">联系人</div>
										<div className="value">
											<Input
												disabled={pageStatus !== 'add'}
												value={baseInfo?.companyLinkMan}
												onChange={(e: AnyObject) => {
													baseInfoValue(e, 'companyLinkMan');
												}}
											/>
										</div>
									</div>
									<div className="item_info">
										<div className="label require_icon">联系电话</div>
										<div className="value">
											<Input
												disabled={pageStatus !== 'add'}
												maxLength={11}
												value={baseInfo?.companyTelephone}
												onChange={(e: AnyObject) => {
													baseInfoValue(e, 'companyTelephone');
												}}
											/>
										</div>
									</div>
								</div>
								<div className="special_item">
									<div className="label require_icon">企业诉求</div>
									<div className="detail_info">
										<TextArea
											disabled={pageStatus !== 'add'}
											rows={4}
											placeholder="请输入内容"
											value={baseInfo?.detail}
											onChange={(e: AnyObject) => {
												baseInfoValue(e, 'detail');
											}}
										/>
									</div>
								</div>
								<div className="special_item ">
									<div className="label">附件（{baseFiles.length}/9）</div>
									<div className="file_info">
										<div>
											{baseFiles.map((item: any, index: number) => {
												return (
													<div className="deal_file" key={index}>
														<div
															className="file-item"
															onClick={() => {
																downLoadHelpDoc(item);
															}}
														>
															{item}
														</div>
														<span
															className="combination-text-btn combination-text-add"
															onClick={() => {
																const combinationList = [...baseFiles];
																combinationList.splice(index, 1);
																setBaseFiles(combinationList);
															}}
														>
															{pageStatus !== 'add' ? null : (
																<DeleteOutlined style={{ fontSize: '18px' }} />
															)}
														</span>
													</div>
												);
											})}
										</div>
										{baseFiles.length < 9 && pageStatus === 'add' ? (
											<IotImgUpload
												defaultImgUrl={''}
												disabled={false}
												getUploadImage={(file: any) => {
													if (file) {
														const combinationList = [...baseFiles];
														combinationList.push(file);
														setBaseFiles(combinationList);
													}
												}}
												multiple={false}
												accept=".pdf,.doc,.txt,."
												uploadType="file"
												limitSize={9}
												isCustomRender={true}
											></IotImgUpload>
										) : null}
									</div>
								</div>
								<div className="special_item">
									<div className="label">图片/视频（{baseImage.length}/6）</div>
									<div className="image_info">
										{baseImage.map((item: any, idx: number) => {
											const isVideo =
												item &&
												(item.indexOf('mp4') > -1 ||
													item.indexOf('rmvb') > -1 ||
													item.indexOf('avi') > -1);
											return (
												<div key={idx}>
													{isVideo ? (
														<>
															<div
																style={{ width: '120px', height: '120px' }}
																className="task-combination-video"
															>
																<video
																	style={{ width: '120px', height: '120px' }}
																	src={`/smartparksysgeneral/v1.0.0/file/download?appId=${userId}&companyId=${companyId}&filename=${item}`}
																	controls={true}
																	controlsList="nodownload"
																></video>
															</div>
															<div className="company-video-title">
																<Tooltip
																	className="company-video-tip-ellipsis"
																	placement="top"
																	title={item}
																>
																	{item || '--'}
																</Tooltip>
																<div
																	className="company-video-text"
																	onClick={() => {
																		const combinationList = [...baseImage];
																		combinationList.splice(idx, 1);
																		setBaseImage(combinationList);
																	}}
																>
																	{pageStatus === 'add' ? (
																		<DeleteOutlined style={{ fontSize: '18px' }} />
																	) : null}
																</div>
															</div>
														</>
													) : (
														<div className="task-opt-center-img-box task-combination-box">
															<div
																style={{
																	width: '120px',
																	height: '120px',
																	marginRight: '30px'
																}}
															>
																<ImageView image={item} imageType={false}></ImageView>
															</div>
															<div className="company-video-title">
																<div className="company-video-tip-ellipsis">
																	<Tooltip placement="top" title={item}>
																		{item || '--'}
																	</Tooltip>
																</div>
																<span
																	className="combination-text-btn combination-text-add"
																	onClick={() => {
																		const combinationList = [...baseImage];
																		combinationList.splice(idx, 1);
																		setBaseImage(combinationList);
																	}}
																>
																	{pageStatus === 'add' ? (
																		<DeleteOutlined
																			style={{
																				fontSize: '18px',
																				marginTop: '5px'
																			}}
																		/>
																	) : null}
																</span>
															</div>
														</div>
													)}
												</div>
											);
										})}
										{baseImage.length < 6 && pageStatus === 'add' ? (
											<IotImgUpload
												defaultImgUrl={''}
												disabled={false}
												getUploadImage={(file: any) => {
													if (file) {
														const combinationList = [...baseImage];
														combinationList.push(file);
														setBaseImage(combinationList);
													}
												}}
												{...extraBox}
											></IotImgUpload>
										) : null}
									</div>
								</div>
							</div>
							{processVariables.dealId || processVariables.orgDealId ? (
								<>{renderDealNode(true, 1)}</>
							) : null}
							{processVariables.status === '0' && pageStatus === 'edit' ? (
								<>{renderDealNode(false, 2)}</>
							) : null}
							{processVariables.status === '1' && pageStatus === 'edit' ? evaluation(false) : null}
							<div className="submit-btn">
								<Button
									style={{ marginRight: '20px' }}
									onClick={() => {
										if (linkFrom === 'INFO') {
											const path = storage.getSession('locationPathname');
											props.history.replace(path);
										}
										props.onHideForm && props.onHideForm();
									}}
								>
									返回
								</Button>
								{pageStatus === 'edit' || pageStatus === 'add' ? (
									<Button type="primary" onClick={saveDealInfo}>
										保存
									</Button>
								) : null}
							</div>
						</>
					)}
				</div>
				{pageStatus !== 'add' ? (
					<div className="right">
						<div className="title">流转信息</div>
						<div className="history">
							<HistoryInfo
								ref={(ref: { copyHistoryList: any[] }) => {
									if (ref?.copyHistoryList.length) {
										setHistoryInfoRef(ref?.copyHistoryList);
									}
								}}
								current={currentData}
							></HistoryInfo>
						</div>
					</div>
				) : null}
				<IotModal
					onRef={(ref: any) => (ModalRef = ref)}
					title="选择人员"
					width="950px"
					// afterClose={closeModal}
					onOk={onOk}
					onCancel={closeModal}
					className="map-position-modal"
				>
					<BaseUser ref={baseUserRef} selectRow={[transferId]} selectRowKeys={selectRowKeys} />
				</IotModal>
			</div>
		</Fragment>
	);
};
export default CompanyDetail;
