const Buildings = {
	getBuildingList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/build/list'
	},
	getBuildingDetail: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/build/info/'
	},
	getBuildingTypeList: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/build/queryBuildTypeInfos'
	},
	addBuilding: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/build/save'
	},
	updBuilding: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/build/update'
	},
	deleteBuilding: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/build/'
	}
};

export default Buildings;
