const Enterprise = {
	getEnterpriseList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/enterprise/page'
	},
	addEnterprise: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/enterprise/save'
	},
	updEnterprise: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/enterprise/update'
	},
	deleteEnterprise: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/enterprise/'
	},
	setCompanyMaster: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/employee/setCompanyMaster'
	},
	getBfenterprise: {
		method: 'POST',
		url: '/smartbfbback/v1.0.0/bfenterprise/list'
	}
};

export default Enterprise;
