import React, { Component } from 'react';
import { Modal } from 'antd';
import './index.less';
import { IotModalProps, IotModalState } from './interface';
import * as redux from 'react-redux';

const { connect } = redux;
class IotModal extends Component<IotModalProps, IotModalState> {
	static getDerivedStateFromProps(nextProps: IotModalProps, prevState: IotModalState) {
		return null;
	}
	constructor(props: IotModalProps) {
		super(props);
		this.state = {
			visible: false
		};
	}
	componentDidMount(): void {
		this.props.onRef(this);
	}
	handleOk = () => {
		if (this.props.onOk) {
			this.props.onOk();
		} else {
			this.close();
		}
	};
	handleCancel = () => {
		this.close();
	};
	open = (cb?: any) => {
		this.setState(
			{
				visible: true
			},
			() => {
				setTimeout(() => {
					cb && cb();
				}, 20);
			}
		);
	};
	close = () => {
		this.setState({
			visible: false
		});
	};
	render() {
		// todo 后续根据设计定义弹窗样式
		const { title = '', className = '' } = this.props;
		return (
			<Modal
				{...this.props}
				destroyOnClose={true}
				visible={this.state.visible}
				onOk={this.handleOk}
				onCancel={this.handleCancel}
				maskClosable={false}
				title={title}
				className={`iot-modal ${className}`}
			>
				{this.props.children}
			</Modal>
		);
	}
}

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(IotModal);
