const JobApi = {
	getJob: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/jobs/getJobList/'
	},
	deleteJob: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/jobs/removeJob/'
	},
	saveJob: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/jobs/addJob/'
	}
};

export default JobApi;
