//employee
const Employee = {
	getEmployeeList: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/employee/queryPage'
	},
	saveEmployee: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/employee/saveParkEmployee'
	},
	editEmployee: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/employee/updateParkEmployee'
	},
	deleteEmployee: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/employee/del/'
	}
};

export default Employee;
