const Element = {
	getElementList: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/unit/list'
	},
	queryBuildInfos: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/unit/queryBuildInfos'
	},
	addElement: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/unit/save'
	},
	updElement: {
		method: 'POST',
		url: '/smartparkbasedata/v1.0.0/unit/update'
	},
	deleteElement: {
		method: 'DELETE',
		url: '/smartparkbasedata/v1.0.0/unit/'
	},
	queryElement: {
		method: 'GET',
		url: '/smartparkbasedata/v1.0.0/unit/info/'
	}
};

export default Element;
